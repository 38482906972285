import React, { useEffect, useState } from "react";
import FormInput from "./FormInput";
import axios from "axios";
import formatCep from "../../../utils/helpers/formatCep";
import formatCpf from "../../../utils/helpers/formatCpf";
import isCpfValid from "../../../utils/helpers/isCpfValid";
import fetchCepData from "../../../utils/helpers/fetchCepData";
import formatTelephone from "../../../utils/helpers/formatTelephone";
import { CheckoutFormData } from "..";
import { SubscriptionCategory } from "../../../types/EventData";
import { rgbHoverColor } from "../../../utils/helpers/rgbHoverColor";

type FormatterFunction = (value: string) => string;

interface FormatterHelpers {
  [key: string]: FormatterFunction;
}

const formatHelpers: FormatterHelpers = {
  cep: formatCep,
  cpf: formatCpf,
  telephone: formatTelephone
}

interface StepRender1Props {
  subscriptionCategories: SubscriptionCategory[]
  defaultFormData: CheckoutFormData;
  onSubmit: (stepData: CheckoutFormData) => void;
  color1: number[]
}

function StepRender1({subscriptionCategories, defaultFormData, onSubmit, color1}: StepRender1Props) {
  const [cpfValid, setCpfValid] = useState<boolean|undefined>();
  const [cepValid, setCepValid] = useState<boolean|undefined>();
  const [formData, setFormData] = useState<CheckoutFormData>(defaultFormData);
  const [placeholderBtnHover, setPlaceholderBtnHover] = useState<boolean>(false);
  const [advanceHover, setAdvanceHover] = useState<boolean>(false);

  const hoverColor = rgbHoverColor(color1);

  useEffect(() => {
    console.log(formData)
  }, [formData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    const formattedValue = formatHelpers[name] ? formatHelpers[name](value) : value;

    setFormData({ ...formData, [name]: formattedValue });
  }

  const handleCepFetch = async () => {
    const { cep } = formData;
    try {
      const cepData = await fetchCepData(cep);
      setCepValid(true);
      setFormData(prevData => ({
        ...prevData,
        district: cepData.bairro,
        state: cepData.uf,
        city: cepData.localidade,
        address: cepData.logradouro,
      }));
    } catch (e) {
      setCepValid(false);
    }
  }

  const handleCpfValidation = async () => {
    setCpfValid(isCpfValid(formData.cpf));
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formData);
  }
  
  return (
    <div className="stepRender1">
      <form onSubmit={handleFormSubmit} className="flex flex-col gap-12">
        <div className="flex justify-center">
          <button
            onClick={() => {
              setFormData({
                email: 'rafaelrsi108@gmail.com',
                telephone: '+55 (62) 98534-6837',
                firstname: 'Rafael',
                surname: 'Godoy',
                cpf: '710.844.931-52',
                birthdate: '22/07/2005',
                state: 'GO',
                city: 'Goiânia',
                district: 'Jardim Goiás',
                number: '220',
                address: 'Rua 46',
                cep: '74805-440',
                complement: 'Ap 1502 torre 2',
                reference: 'Em frente parque flamboyant',
                categoryid: subscriptionCategories[0].id
              })
            }}
            onMouseEnter={() => setPlaceholderBtnHover(true)}
            onMouseLeave={() => setPlaceholderBtnHover(false)}
            className="cursor-pointer px-8 py-3 text-white rounded transition-all" 
            style={{
              backgroundColor: `rgb(${placeholderBtnHover ? hoverColor : color1})`
            }}
          >
            SET PLACEHOLDER
          </button>
        </div>
        <div className="flex gap-2 md:gap-24">
          <FormInput 
            type="email"
            name="email"
            placeholder="Seu endereço de email"
            title="EMAIL" 
            value={formData.email}
            onChange={handleChange}
            required
          />
          <FormInput 
            type="text" 
            name="telephone" 
            placeholder="+55 (DDD) Número" 
            title="CELULAR"
            value={formData.telephone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex gap-2 md:gap-24">
          <FormInput 
            type="text"
            name="firstname"
            placeholder="Ex: John"
            title="NOME" 
            value={formData.firstname}
            onChange={handleChange}
            required
          />
          <FormInput 
            type="text" 
            name="surname" 
            placeholder="Ex: Doe" 
            title="SOBRENOME"
            value={formData.surname}
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex gap-2 md:gap-24">
          <FormInput 
            id="cpfinput"
            type="text"
            name="cpf"
            placeholder="123.456.789-09"
            title="CPF"
            value={formData.cpf}
            onChange={handleChange}
            onBlur={handleCpfValidation}
            required
            invalid={cpfValid == false}
          />
          <FormInput 
            type="date" 
            name="birthdate" 
            placeholder="DD/MM/YYYY"
            title="DATA DE NASCIMENTO" 
            value={formData.birthdate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex gap-2 md:gap-24">
          <FormInput 
            type="text"
            name="cep"
            placeholder="00000-000"
            title="CEP" 
            value={formData.cep}
            onChange={handleChange}
            onBlur={handleCepFetch}
            invalid={cepValid == false}
            required
          />
          <div className="w-1/3">
            <label className="font-semibold text-lg">ESTADO</label>
            <select value={formData.state} onChange={handleChange} className="bg-slate-50  border-2 border-slate-200 mt-3 px-2 py-1 w-full">
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
              <option value="EX">Estrangeiro</option>
            </select>
          </div>
          <FormInput 
            type="text" 
            name="city" 
            placeholder="Digite o nome da cidade" 
            title="CIDADE" 
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex gap-2 md:gap-24">
          <FormInput 
            type="text" 
            name="district"
            placeholder="Digite o nome do seu bairro"
            title="BAIRRO"
            value={formData.district}
            onChange={handleChange}
            required
          />
          <FormInput 
            type="text"
            name="number"
            placeholder="Ex: 220"
            title="NÚMERO"
            value={formData.number}
            onChange={handleChange}
          />
          <FormInput 
            type="text" 
            name="address" 
            placeholder="Digite a rua do seu endereço" 
            title="ENDEREÇO"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex gap-2 md:gap-24">
          <FormInput 
            type="text" 
            name="complement" 
            placeholder="Complemento do endereço" 
            title="COMPLEMENTO"
            value={formData.complement}
            onChange={handleChange}
            required
          />
          <FormInput 
            type="text" 
            name="reference" 
            placeholder="Digite uma referencia para o endereço" title="REFERÊNCIA" 
            value={formData.reference}
            onChange={handleChange}
          />
        </div>
        <div className="flex gap-2 md:gap-24">
          <select name="categoryid" value={formData.categoryid} onChange={handleChange} className="bg-slate-50 border-2 border-slate-200 mt-3 px-2 py-1 w-full">
            {subscriptionCategories.filter((sc) => !sc.hidden).map((subscriptionCategory, index) => (
              <option selected={subscriptionCategory.id == formData.categoryid} value={subscriptionCategory.id}>{subscriptionCategory.title} - (1x) R$ {subscriptionCategory.prevalue}</option>
            ))}
          </select>
        </div>
        <div className="flex justify-center">
          <input
            type="submit" 
            value="Avançar"
            onMouseEnter={() => setAdvanceHover(true)}
            onMouseLeave={() => setAdvanceHover(false)}
            className="cursor-pointer px-8 py-3 text-white rounded transition-all" 
            style={{
              backgroundColor: `rgb(${advanceHover ? hoverColor : color1})`
            }}
          />
        </div>
      </form>
    </div>
  );
}

export default StepRender1;