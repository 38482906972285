import { Category, EventData, FunctionData, Participant, ScheduleDay, SchedulePlace, Sponsor, SponsorCategory, SubscriptionCategory } from "../../types/EventData";

function parseDataFormat(date?: Date) : string | null {
  if (!date) {
    return null;
  }

  return date.toISOString().replace(/\.\d{3}Z$/, '');
}

export function parseDraftData(draftData: EventData) {
  const parsedData: any = structuredClone(draftData);

  const categoriesIdMap: Record<string, number> = {};
  const participantsIdMap: Record<string, number> = {};
  const functionsIdMap: Record<string, number> = {};
  const dayIdMap: Record<string, number> = {};
  const placeIdMap: Record<string, number> = {};
  const moduleIdMap: Record<string, number> = {};
  const programmingIdMap: Record<string, number> = {};
  const sponsorsCategoriesMap: Record<string, number> = {};
  const sponsorsMap: Record<string, number> = {};
  const subscriptionCategoryIdMap: Record<string, number> = {};
  
  const allAssignments: {id: number, participantid: number, functionid: number}[] = [];
  
  let idCounter = 1;
  let assignmentIdCounter = 1;

  const getNewId = (oldId: any, map: Record<string, number>) => {
    if (!map[oldId]) {
      map[oldId] = idCounter++;
    }
    return map[oldId];
  };

  const categories = parsedData.categories.map((category: Category) => {
    return {
      ...category,
      id: getNewId(category.id, categoriesIdMap)
    }
  });

  const participants = parsedData.participants.map((participant: Participant) => {
    // const category = participant.category ? {
    //   ...participant.category,
    //   id: categoriesIdMap[participant.category.id]
    // } : {name: "<Sem Categoria>", id: -1};
    
    return {
      ...participant,
      id: getNewId(participant.id, participantsIdMap),
      // categoryid: category.id,
      // category,
      categories: participant.categories.map((category) => {
        console.log(category)
        return {
          id: 0,
          categoryid: categoriesIdMap[category.id] || -1,
          participantid: 0
        }
      }),
    }
  });

  const functionsData = parsedData.functions.map((functionData: FunctionData) => {
    return {
      ...functionData,
      id: getNewId(functionData.id, functionsIdMap)
    }
  });

  const scheduleDays = parsedData.schedule.days.map((day: ScheduleDay, index: number) => {
    const dayId = getNewId(day.id || index, dayIdMap);
    const places = day.dayplaces.map((place: SchedulePlace) => {
      const placeId = getNewId(place.id, placeIdMap);
      const modules = place.placemodules.map((module) => {
        const moduleId = getNewId(module.id, moduleIdMap);
        const programming = module.moduleprogrammings.map((programming) => {
          const programmingId = getNewId(programming.id, programmingIdMap);
          const assignmentIds = programming.assignments.map((assignment) => {
            const assignmentId = assignmentIdCounter++;
            return {
              id: assignmentId,
              participant: participants.find((participant: any) => participant.id == participantsIdMap[assignment.participant.id]),
              function: {
                ...assignment.function,
                id: getNewId(assignment.function.id, functionsIdMap)
              }
            }
          });
          return {
            ...programming,
            id: programmingId,
            assignments: assignmentIds
          }
        });
        const moduleAssignmentIds = module.assignments.map((assignment) => {
          const assigmentId = assignmentIdCounter++;
          return {
            id: assigmentId,
            participant: participants.find((participant: any) => participant.id == participantsIdMap[assignment.participant.id]),
            function: {
              ...assignment.function,
              id: getNewId(assignment.function.id, functionsIdMap)
            }
          }
        })
        return {
          ...module,
          id: moduleId,
          independent: module.independent,
          startime: new Date(module.startime.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace(/\.\d{3}Z$/, ''),
          endtime: new Date(module.endtime.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace(/\.\d{3}Z$/, ''),
          moduleprogrammings: programming,
          assignments: moduleAssignmentIds
        }
      })
      place.date ??= day.date;
      return {
        ...place,
        id: placeId,
        date: new Date(place.date.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace(/\.\d{3}Z$/, ''),
        placemodules: modules
      }
    });
    return {
      ...day,
      dayplaces: places,
      id: dayId,
      date: new Date(day.date.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace(/\.\d{3}Z$/, ''),
      index: index + 1,
    }
  });

  const sponsorsCategories = parsedData.sponsors.map((sponsorCategory: SponsorCategory) => {
    const sponsors = sponsorCategory.participants.map((sponsor: Sponsor) => ({
      ...sponsor,
      id: getNewId(sponsor.id, sponsorsMap)
    }))
    return {
      ...sponsorCategory,
      id: getNewId(sponsorCategory.id, sponsorsCategoriesMap),
      participants: sponsors
    }
  });

  const subscriptionCategories = parsedData.subscriptioncategories.map((subCat: SubscriptionCategory) => {
    return {
      ...subCat,
      id: getNewId(subCat.id, subscriptionCategoryIdMap)
    }
  });

  parsedData.categories = categories;
  parsedData.participants = participants;
  parsedData.functions = functionsData;
  parsedData.schedule.days = scheduleDays;
  parsedData.assignments = allAssignments;
  parsedData.sponsors = sponsorsCategories;
  parsedData.subscriptioncategories = subscriptionCategories;

  parsedData.startdate = parseDataFormat(parsedData.startdate);
  parsedData.creationdate = parseDataFormat(parsedData.creationdate);
  parsedData.paymentdate = parseDataFormat(parsedData.paymentdate);

  parsedData.eventid ??= 0;

  parsedData.administratorid = null;
  parsedData.nde = "";
  parsedData.status = "";

  parsedData.schedule.id = 0;

  return parsedData;
}