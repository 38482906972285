import React, { useEffect, useState } from "react";

import EventBanner from "../../components/Introduction/EventBanner";
import IntroductionSection from "../../components/Introduction/IntroductionSection";
import LayoutSection from "../../components/LayoutSection";
import Header from "../../components/Header";
import SpeakerCarousel from "../../components/Speakers/SpeakerCarousel";
import QuickLinks from "../../components/QuickLinks";
import SectionHeader from "../../components/SectionHeader";
import LocationDetails from "../../components/Location/LocationDetails";
import LocationAddress from "../../components/Location/LocationAddress";
import LocationMap from "../../components/Location/LocationMap";
import SponsorsContainer from "../../components/SponsorsContainer";
import LayoutContainer from "../../components/LayoutContainer";
import Navbar from "../../components/Header/Navbar";
import VideoPlayer from "../../utils/ui/VideoPlayer";
import useWindowSize from "../../hooks/useWindowSize";
import Loading from "../Loading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { EventData } from "../../types/EventData";
import { fetchEventData } from "../../utils/api";

interface Layout4Props {
  eventSlug?: string;
  preview?: boolean;
  previewData?: EventData;
  onEventPageRedirect?: (page: string) => void;
}

function Layout4({
  eventSlug, 
  preview, 
  previewData,
  onEventPageRedirect
}: Layout4Props) {
  const {windowSize} = useWindowSize();
  const [data, setData] = useState<EventData>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async() => {
      if (preview) {
        setData(previewData);
        setLoading(false);
        return;
      }

      if (eventSlug) {
        const fetchData = await fetchEventData(eventSlug);
        setData(fetchData);
        setLoading(false);
      }
    };

    if (!data) {
      fetchData();
    }
  }, [previewData, preview, eventSlug, data]);

  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="Layout4 layout">
        <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
          <Navbar 
            className="py-5" 
            color1={data?.color1!} 
            logo={data?.logourl!} 
            layout={data?.layout!} 
            noLogo 
            preview={preview}
            onPreviewRedirect={onEventPageRedirect}
          />
          <div className="flex flex-col-reverse xl:flex-row gap-8 items-start">
             <LayoutSection className="xl:max-w-lg 2xl:max-w-xl">
              <IntroductionSection
                authorCenter
                title="Introdução"
                content={data!.introduction}
                author={data?.participants.find((participant) => participant.introductionauthor)!}
              />
            </LayoutSection>
            <div className="flex flex-col w-full justify-center items-center gap-24">
              <EventBanner 
                color1={data?.color1!}
                color2={data?.color2!}
                banner={data?.banner!}
                eventDate={data?.startdate!}
                place={data?.place!}
              />
              <Header logo={data?.logourl!} />
            </div>
          </div>
          <LayoutSection>
            <QuickLinks 
              horizontal 
              eventSlug={eventSlug} 
              preview={preview}
              onPreviewRedirect={onEventPageRedirect}
            />
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Palestrantes</SectionHeader>
            <SpeakerCarousel
              color1={data?.color1!}
              authors={data?.participants.filter((participant) => participant.isspeaker)!}
            />
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Localização</SectionHeader>
            <div className="flex">
              <div>
                <LocationMap
                  width={windowSize.width < 900 ? 300 : 450 * (windowSize.width * 0.0008)}
                  height={windowSize.width < 900 ? 320 : 400}
                  className="flex justify-center mb-12 mt-4"
                  location={data?.place!} 
                />
                <LocationAddress
                  street={data?.place.street!}
                  number={data?.place.number!}
                  district={data?.place.district!}
                  city={data?.place.city!}
                  state={data?.place.state!}
                  telephone={data?.place.telephone!}
                />
              </div>
              <div className="px-12 m-auto">
                <div
                  style={{
                    maxWidth: (windowSize.width < 1200 ? '400px' : '550px'),
                    margin: 'auto'
                  }}
                >
                  <Swiper
                    modules={[Navigation, Pagination]}
                    pagination={{clickable: true}}
                    navigation={true}
                  >
                    {data?.place.imgs?.map((placeImgSrc) => {
                      const width = windowSize.width < 1200 ? 300 : 400;
                      const height = windowSize.width < 900 ? 320 : 400;
                      return (
                        <SwiperSlide>
                          <img 
                            className={`w-[${width}px] h-[${height}px] pb-4 m-auto`}
                            src={placeImgSrc.src}
                            alt={placeImgSrc.alt} 
                          />
                        </SwiperSlide>
                      )
                    })}
                    {data?.place.youtubeurl && (
                      <SwiperSlide>
                        <VideoPlayer
                          url={data?.place.youtubeurl!}
                          width={windowSize.width < 1200 ? 300 : 450}
                          height={windowSize.width < 900 ? 320 : 400}
                        />
                      </SwiperSlide>
                    )}
                  </Swiper>
                  <LocationDetails name={data?.place.name!} description={data?.place.description!}  />
                </div>
              </div>
            </div>
          </LayoutSection>
          <LayoutSection>
            <SponsorsContainer 
              sponsorCategories={data?.sponsors!}
            />
          </LayoutSection>
        </LayoutContainer>
    </div>
  );
}

export default Layout4;