import React, { useState } from "react";
import { UploadButton } from "@bytescale/upload-widget-react";
import ReactCountryFlag from "react-country-flag"

import { Category, Participant } from "../../../../types/EventData";
import ConfirmActionModal from "../../../../utils/ui/ConfirmActionModal";
import { getNationalities } from "../../../../utils/helpers/getNationalities";
import { FaTrash } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import truncateString from "../../../../utils/helpers/truncateString";

const options = {
  apiKey: "public_12a1z8V6fXbMfWWFEV3xj6yqzse5",
  maxFileCount: 1,
  showFinishButton: false,
  styles: {
    colors: {
      primary: "#FF6B00"
    },
  },
  locale: {
    "uploadFileBtn": "Enviar imagem",
    "orDragDropFile": "...ou arraste uma imagem.",
    "cancelBtn": "cancelar",
    "cancelPreviewBtn": "Cancelar",
    "continueBtn": "Continuar",
    "removeBtn": "remover",
    "removeBtnClicked": "removido",
    "submitBtnLoading": "Porfavor espere...",
  },
};

// export type ParticipantsProps = "id" | "name" | "description" | "cpf" | "profilepic" | "isspeaker" | "category" | "introductionauthor";

interface EditParticipantFormProps {
  participant: Participant;
  categories: Category[];
  onChange: (key: keyof Participant, value: any, participantId: string) => void;
}

function EditParticipantForm({participant, categories, onChange}: EditParticipantFormProps) {
  const [isImageHovering, setImageHovering] = useState<boolean>(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false );

  const handleModalConfirm = () => {
    setIsConfirmModalVisible(false);
    onChange("introductionauthor", !participant.introductionauthor, participant.id);
  }

  const handleModalCancel = () => {
    setIsConfirmModalVisible(false);
  }

  return (
    <div className="mt-12 2xl:mt-0">
      <h2 className="text-3xl font-bold mb-4">Editar Pessoa</h2>
      <div className="flex flex-col gap-6 mt-8">
        <div className="flex gap-4">
          <div>
            <h2 className="text-xl font-bold">Nome</h2>
            <input 
              type="text" 
              value={participant.name}
              onChange={(e) => onChange("name", e.target.value, participant.id)}
              placeholder="DIGITE O NOME DA PESSOA"
              className="w-full text-xs shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border max-w-80"
            />
          </div>
          <div>
            <h2 className="text-xl font-bold">
              Nacionalidade 
              {participant.nationality && (
                <span className="ms-2 text-gray-500">
                  ( <ReactCountryFlag countryCode={participant.nationality} svg /> )
                </span>
              )}
            </h2>
            <select
              className="w-full text-xs shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border max-w-80 bg-white"
              onChange={(e) => onChange("nationality", e.target.value, participant.id)}
            >
              <option value="">Selecione uma nacionalidade</option>
              {getNationalities().map((nationality) => (
                <option 
                  key={nationality.code} 
                  value={nationality.code}
                  selected={participant.nationality == nationality.code}
                >
                  {nationality.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex gap-2 text-xs">
            <div className="flex flex-col items-center gap-4">
              <h2 className="text-md font-bold">Palestrante</h2>
              <input 
                type="checkbox"
                checked={participant.isspeaker}
                onChange={(e) => onChange("isspeaker", e.target.checked, participant.id)}
                style={{
                  width: '60px'
                }}
              />
            </div>
            <div className="flex flex-col items-center gap-4">
              <h2 className="text-md font-bold">Destaque</h2>
              <input 
                type="checkbox"
                checked={participant.introductionauthor}
                onChange={() => setIsConfirmModalVisible(true)}
                style={{
                  width: '60px'
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-16">
          <div>
            <div className="flex">
              <h2 className="text-xl font-bold">Foto</h2>
              <p className="text-xs text-gray-500 italic">Opcional</p>
            </div>
            <UploadButton
              options={options}
              onComplete={(files) => {
                let fileUrl = files.map(x => x.fileUrl).join("\n");
              
                if (fileUrl == '') {
                  return;
                }

                onChange("profilepic", fileUrl, participant.id)
              }}
            >
              {({onClick}) =>
                <div className="relative"
                  style={{
                    width: '150px',
                    height: '150px'
                  }}
                >
                  <img 
                    src={participant.profilepic}
                    className="absolute my-2 cursor-pointer hover:brightness-75 transition-all transition-duration-300 border-2 border-black/10 py-2"
                    onClick={onClick}
                    onMouseEnter={() => setImageHovering(true)}
                    onMouseLeave={() => setImageHovering(false)}
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'contain'
                    }}
                  />
                  <p className={`absolute h-full w-full flex justify-center items-center font-bold text-white pointer-events-none px-4 text-center ${isImageHovering ? 'block' : 'hidden'}`}>
                    Clique para editar
                  </p>
                </div>
              }
            </UploadButton>
          </div>
          <div className="w-full">
            <div className="flex">
              <h2 className="text-xl font-bold">Mini-Curriculo</h2>
              <p className="text-xs text-gray-500 italic">Opcional</p>
            </div>
            <textarea
              value={participant.description}
              onChange={(e) => onChange("description", e.target.value, participant.id)}
              placeholder="EX: ESPECIALISTA EM NEUROGENÉTICA"
              className="w-full text-xs shadow-lg rounded-xl mt-2 p-2 border xl:min-w-96 resize-none"
              rows={8}
            />
          </div>
        </div>
        {/* <div className="flex flex-col gap-6"> */}
        <div className="flex gap-16">
          <div className="flex flex-col gap-2">
            <h2 className="text-md font-bold">Selecione uma categoria</h2>
            <select
              className="w-full text-sm shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border max-w-80 bg-white"
              // onChange={(e) => console.log(e.target.value)}
              onChange={(e) => onChange("categories", [...participant.categories, categories.find((category) => e.target.value == category.id)], participant.id)}
            >
              <option value="" selected>--</option>
              {categories.filter((category) => participant.categories.findIndex((pc) => pc.id == category.id) == -1).map((category) => (
                // <option selected={participant.category?.id == category.id} key={category.id} value={category.id}>{category.name}</option>
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
          </div>
          <div>
            <h2 className="text-md font-bold">Categorias</h2>
            <ul className="flex flex-col gap-2 w-[300px] h-[150px] overflow-y-scroll">
              {participant.categories.length === 0 && (
                <p className="text-lg text-black/20 font-bold">Participante sem categorias.</p>
              )}
              {participant.categories.map((category, index) => (
                <li className="bg-white border-stone-400 border w-full h-8 rounded-full shadow-lg flex items-center justify-between px-4">
                  <span>
                    {truncateString(category.name, 20)}
                  </span>
                  <button
                    data-tooltip-id={`category-${participant.name}-${index + 1}-tooltip`}
                    data-tooltip-content="Deletar"
                    data-tooltip-place="bottom"
                    onClick={() => onChange("categories", participant.categories.filter((c) => c.id != category.id), participant.id)}
                  >
                    <FaTrash />    
                  </button>
                  <Tooltip id={`category-${participant.name}-${index + 1}-tooltip`} />
                </li>
              ))}
            </ul>
          </div>
          {/* <div>
            <h2 className="text-xl font-bold">Categoria</h2>
            <select
              className="w-full text-sm shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border max-w-80 bg-white"
              onChange={(e) => onChange("category", categories.find((category) => e.target.value == category.id), participant.id)}
            >
              <option>&lt;Sem Categoria&gt;</option>
              {categories.map((category) => (
                <option selected={participant.category?.id == category.id} key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
          </div> */}
        </div>
      </div>
      <ConfirmActionModal 
        visible={isConfirmModalVisible}
        question="Você tem certeza que deseja alterar o autor da introdução de evento ?"
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
      />
    </div>
  );
}

export default EditParticipantForm;
