import React, { ReactNode } from "react";
import { SchedulePlace } from "../../../../../types/EventData";
import DraggableList from "../../DraggableList";
import truncateString from "../../../../../utils/helpers/truncateString";

interface PlaceListProps {
  places: SchedulePlace[];
  selectedId?: string;
  handlePlaceSelect: (id?: string) => void;
  handlePlaceCreate: () => void;
  handlePlaceUpdate: (newPlaces: SchedulePlace[]) => void;
  handlePlaceDelete: (newPlaces: SchedulePlace[]) => void;
}

export default function PlaceList({
  places,
  selectedId,
  handlePlaceSelect,
  handlePlaceCreate,
  handlePlaceUpdate,
  handlePlaceDelete
}: PlaceListProps) {
  return (
    <DraggableList 
      id="droppable-places"
      title="Salas"
      emptyText="Adicione uma nova sala."
      className="h-48 2xl:h-64 overflow-y-scroll px-2"
      items={places}
      selectedId={selectedId}
      onCreate={handlePlaceCreate}
      onSelect={handlePlaceSelect}
      onOrderChange={handlePlaceUpdate}
      onDelete={(id: string) => handlePlaceDelete(places.filter(place => place.id !== id))}
      renderItem={(item: SchedulePlace, index: number) => (
        <p className="uppercase text-sm font-semibold">
          {item.title == '' ? '<Digite o nome do local>' : truncateString(item.title, 22)}
        </p>
      )}
    />
  );
}
