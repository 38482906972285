import Navbar from "../Navbar";
import Layout3Header from "../LayoutsHeader/Layout3";
import { EventData, Layout } from "../../../types/EventData";

type LayoutsHeaderGateProps = {
  data: EventData;
  preview?: boolean;
  onPreviewRedirect?: (page: string) => void;
  layout: Layout;
}
// TODO: Colocar o data e o preview em tudo que usa o layouts e header.

function LayoutsHeaderGate({
  data,
  preview,
  onPreviewRedirect,
  layout
}: LayoutsHeaderGateProps) {
  return (
    <div className="layoutsHeaderGate">
      {(layout != "layout3") ? (
        <Navbar 
          color1={data?.color1!} 
          className="pb-2 pt-6" 
          logo={data?.logourl!} 
          layout={layout}
          preview={preview}
          onPreviewRedirect={onPreviewRedirect}
        />
      ) : <></>}
      {(layout == "layout3") ? (
        <Layout3Header
          logourl={data?.logourl!}
          layout={layout}
          color1={data?.color1!}
          preview={preview}
          onPreviewRedirect={onPreviewRedirect}
        />
      ) : <></>}
    </div>
  );
}

export default LayoutsHeaderGate;