import React, { useState } from "react";
import { rgbHoverColor } from "../../../../utils/helpers/rgbHoverColor";

interface NavItemProps extends React.HTMLAttributes<HTMLLIElement> {
  children: React.ReactNode;
  column?: boolean;
  redirect?: string;
  className?: string;
  hasDropdown?: boolean;
  color1?: number[];
  isMobile?: boolean;
  preview?: boolean;
  onClick?: () => void;
}

function NavItem({
  children,
  redirect,
  hasDropdown,
  column,
  className,
  preview,
  onClick,
  color1 = [59, 130, 246],
  isMobile = false,
  ...props
}: NavItemProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hoverColor = rgbHoverColor(color1);

  const dropdownItems = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === NavItem) {
      return child;
    }
  });

  const contentItems = React.Children.map(children, (child) => {
    if (
      React.isValidElement(child) && child.type !== NavItem ||
      typeof child === "string" || typeof child === "number"
    ) {
      return child;
    }
    return null;
  });

  const handleMainClick = (e: React.MouseEvent) => {
    if (hasDropdown && isMobile) {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(!isOpen);
    }
  };

  return (
    <li
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
      className={`${redirect && "hover:cursor-pointer"} z-10 navitem transition-all text-white py-1 rounded-md w-full text-center uppercase relative ${className} ${
        hasDropdown && !isMobile ? 'group' : ''
      }`}
      style={{
        backgroundColor: `rgb(${isHovered ? hoverColor : color1})`
      }}
      {...props}
    >
      <div 
        className="block w-full h-full" 
        onClick={handleMainClick}
      >
        {(redirect || preview) ? (
          <a 
            className="block w-full h-full" 
            href={preview ? undefined : redirect}
            onClick={(e) => {
              if (preview && onClick) {
                onClick();
              }
              
              if (isMobile && hasDropdown) {
                e.stopPropagation();
              }
            }}
          >
            {contentItems}
          </a>
        ) : (
          contentItems
        )}
      </div>

      {hasDropdown && dropdownItems && (
        <ul
          className={`dropdown-content ${isMobile ? 'static' : 'absolute'} ${
            column ? 'top-0 right-full text-white' : 'left-0 top-full'
          } rounded-lg text-black w-full ${
            isMobile 
              ? `max-h-0 overflow-hidden transition-all duration-300 ease-in-out ${isOpen ? 'max-h-96 mt-1' : ''}`
              : `max-h-0 opacity-0 overflow-hidden group-hover:max-h-96 group-hover:opacity-100 transition-all duration-300 ease-in-out`
          }`}
          style={{
            ...(column ? { marginRight: '0.1rem' } : {}),
            backgroundColor: `rgb(${color1})`
          }}
        >
          {dropdownItems}
        </ul>
      )}
    </li>
  );
}

export default NavItem;