import React, { useEffect, useState } from 'react'
import LayoutContainer from '../components/LayoutContainer';
import LayoutSection from '../components/LayoutSection';
import SectionHeader from '../components/SectionHeader';
import SponsorsContainer from '../components/SponsorsContainer';
import LayoutsHeaderGate from '../components/Header/LayoutsHeaderGate';
import Loading from "./Loading";
import ScheduleController from '../components/ScheduleController';
import CurriculumModal from '../components/CurriculumModal';
import { EventData, Layout, Participant } from '../types/EventData';
import { fetchEventData } from '../utils/api';

type ScheduleProps = {
  eventSlug?: string;
  preview?: boolean;
  previewData?: EventData;
  previewLayout?: Layout;
  onEventPageRedirect?: (page: string) => void;
}

function Schedule({
  eventSlug,
  preview,
  previewData,
  previewLayout,
  onEventPageRedirect
}: ScheduleProps) {
  const [data, setData] = useState<EventData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [participant, setParticipant] = useState<Participant | null>(null);

  useEffect(() => {
    const fetchData = async() => {
      if (preview) {
        setData(previewData);
        setLoading(false);
        return;
      }

      if (eventSlug) {
        const fetchData = await fetchEventData(eventSlug);
        setData(fetchData);
        setLoading(false);
      }
    };

    if (!data) {
      fetchData();
    }
  }, [previewData, preview, eventSlug, data]);

  if (loading) {
    return (<Loading />);
  }

  const scheduleData = data?.schedule;

  const handleCurriculumClick = (participant: Participant) => {
    setParticipant(participant);
    setIsModalOpen(true);
  } 

  return (
    <div className="Schedule layout">
      <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
        {data && (
          <LayoutsHeaderGate 
            preview={preview}
            data={data}
            onPreviewRedirect={onEventPageRedirect}
            layout={previewLayout || data.layout}
          />
        )}
        <LayoutSection>
          <SectionHeader>PROGRAMAÇÃO</SectionHeader>
          <ScheduleController 
            days={scheduleData?.days!}
            selectedIndex={selectedIndex}
            color1={data?.color1!}
            onDaySelect={setSelectedIndex}
            onCurriculumClick={handleCurriculumClick}
          />
        </LayoutSection>
        <LayoutSection>
          <SponsorsContainer 
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
      {participant && (
        <CurriculumModal
          participant={participant}
          isModalOpen={isModalOpen}
          setIsModalOpen={() => setIsModalOpen(false)}
        />
      )}
    </div>
  )
}

export default Schedule;