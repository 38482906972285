import React from 'react'
import { Participant } from '../../types/EventData';

type ProfileBadgeData = {
  profile : Participant;
  [key: string] : any;
}

function ProfileBadge({profile, ...props} : ProfileBadgeData) {
  return (
    <div className='text-center min-w-fit flex flex-col items-center max-w-80'>
      <img className='profile-pic mb-4' src={profile.profilepic} />
      <h3 className="text-lg font-semibold">{profile.name}</h3>
      {/* <p className="mb-5 text-neutral-400">{profile.category?.name}</p> */}
    </div>
  )
}

export default ProfileBadge;