import React, { useState } from "react";
import { Category, EventData, Participant } from "../../../types/EventData";
import EditCategoryForm from "../FormInputs/EditCategoryForm";
import CategoryList from "../FormInputs/CategoryList";

type CategoriesProps = {
  eventData: EventData;
  handleFormChange: (data: {[key: string]: any}) => void;
  submitForm: () => void;
}

function Categories({eventData, handleFormChange}: CategoriesProps) {
  const [selectedCategory, setSelectedCategory] = useState<Category>();

  const handleCategorySelect = (selectedId: string) => {
    setSelectedCategory(eventData.categories.find((category) => category.id == selectedId));
  }

  const handleCategoryUpdate = (categoryUpdate: Category[], participantsUpdate?: Participant[]) => {
    handleFormChange({
      categories: categoryUpdate,
      participants: participantsUpdate ? participantsUpdate : eventData.participants
    });
  }

  const handleCategoryChangeInput = (key: keyof Category, value: any, categoryId: string) => {
    const categoryUpdate = [...eventData.categories];
    const participantsUpdate = [...eventData.participants];
    const category = categoryUpdate.find(category => category.id === categoryId);

    if (category) {
      //@ts-ignore
      category[key] = value;
    }

    participantsUpdate.forEach((participant: Participant) => {
      const participantCategoryIndex = participant.categories.findIndex(c => c.id == categoryId);
      if (participantCategoryIndex) {
        participant.categories[participantCategoryIndex] = value;
      }
      // if (participant.categoryid == categoryId && participant.category) {
        //@ts-ignore
        // participant.category[key] = value;
      // }
    });

    handleFormChange({
      categories: categoryUpdate
    });
  }

  return (
    <div className="commissions">
      <div className="px-36 py-16 flex">
        <div>
          <h2 className="text-3xl font-bold mb-4">Categorias</h2>
          <CategoryList 
            eventData={eventData}
            categories={eventData.categories}
            onDelete={handleCategoryUpdate}
            onCreate={handleCategoryUpdate}
            onSelect={handleCategorySelect}
            onDrag={handleCategoryUpdate}
          />
        </div>
        <div className="flex-grow max-w-[800px] px-12">
          {selectedCategory ? (
            <EditCategoryForm 
              category={selectedCategory}
              onChange={handleCategoryChangeInput}
            />
          ) : (
            <div className="flex items-center h-full">
              <p className="text-lg text-gray-300 font-bold mb-4">Selecione uma categoria para editar</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Categories;