import React, { useEffect, useState } from "react";
import TableHead from "../EventsDashboardTable/TableHead";
import { motion } from "framer-motion";
import { deleteDraftById, fetchAllDrafts } from "../../utils/api";
import TableCell from "../EventsDashboardTable/TableCell";
import convertDateToString from "../../utils/helpers/convertDateToString";
import { RiPencilFill } from "react-icons/ri";
import { Tooltip } from "react-tooltip";
import { DraftData } from "../../types/Drafts";
import { FaTrashAlt } from "react-icons/fa";
import Modal from "react-responsive-modal";
import { ClipLoader } from "react-spinners";
import truncateString from "../../utils/helpers/truncateString";
import ConfirmActionModal from "../../utils/ui/ConfirmActionModal";

interface EventsDraftTableProps {
  onLoad?: () => void;
}

type DraftItem = {
  id?: number;
  logoUrl: string;
  nde?: string;
  name: string;
  draftDate: Date;
  route?: string;
  manager: string;
}

export default function EventsDraftTable({
  onLoad
}: EventsDraftTableProps) {
  const [drafts, setDrafts] = useState<DraftItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [toDeleteDraft, setToDeleteDraft] = useState<DraftItem | null>(null);

  useEffect(() => {
    (async () => {
      const allDrafts = await fetchAllDrafts();
      setDrafts(allDrafts.map((draft) => ({
        id: draft.draftid,
        logoUrl: draft.logourl,
        name: truncateString(draft.banner.title, 35),
        nde: draft.nde,
        route: draft.route,
        manager: 'JAIME',
        draftDate: draft.creationdate || new Date()
      })));
      console.log(allDrafts);
    })();

    onLoad && onLoad();
  }, []);

  const handleDraftDelete = async (toDeleteDraft: DraftItem | null) => {
    if (!toDeleteDraft || !toDeleteDraft?.id) {
      return console.log("Draft with empty id.");
    }

    setLoading(true);
    await deleteDraftById(toDeleteDraft.id);
    setDrafts(prev => prev.filter((draft) => draft.id != toDeleteDraft.id));
    setToDeleteDraft(null);
    setLoading(false);
  }

  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      transition={{duration: 0.5}}
      className="eventsDraftTable"
    >
      <table className="table-auto leading-normal w-[900px] xl:w-[1200px]">
        <thead>
          <tr className="text-center">
            <TableHead>Logo</TableHead>
            <TableHead>Nome do Rascunho</TableHead>
            <TableHead>Data do Evento</TableHead>
            <TableHead>Ação</TableHead>
            <TableHead>Rota</TableHead>
            <TableHead>Gestor</TableHead>
          </tr>
        </thead>
        <tbody>
          {drafts.length == 0 && (
            <tr>
              <td 
                colSpan={9} 
                className="text-center py-5 text-stone-200 font-semibold"
              >
                Nenhum rascunho encontrado.
              </td>
            </tr>
          )}
          {drafts.map((draft, index) => (
            <tr className="bg-gray-50 hover:bg-gray-100 transition-all">
              <TableCell>
                <img src={draft.logoUrl} style={{height: '50px'}} />
              </TableCell>
              <TableCell>
                {draft.name.replace(' ', '') != '' ? (
                  <span>{draft.name}</span>
                ) : (
                  <span className="text-black/40">&lt;Rascunho vazio&gt;</span>
                )}
              </TableCell>
              <TableCell>{convertDateToString(new Date(draft.draftDate))}</TableCell>
              <TableCell>
                <a
                  href={`/events/draft/${draft.id}`}
                  className="inline-block me-2 transition-transform duration-200 ease-in-out hover:scale-110 outline-none"
                  data-tooltip-id={`drafts-edit-tooltip-${index}`}
                  data-tooltip-content="Editar"
                  data-tooltip-place="bottom"
                >
                  <RiPencilFill style={{height: '30px', width: '30px'}} />
                  <Tooltip id={`drafts-edit-tooltip-${index}`} />
                </a>
                <a
                  onClick={() => setToDeleteDraft(draft)}
                  className="inline-block me-2 transition-transform duration-200 ease-in-out hover:scale-110 outline-none cursor-pointer"
                  data-tooltip-id={`drafts-delete-tooltip-${index}`}
                  data-tooltip-content="Deletar"
                  data-tooltip-place="bottom"
                >
                  <FaTrashAlt style={{height: '30px', width: '30px'}} />
                  <Tooltip id={`drafts-delete-tooltip-${index}`} />
                </a>
              </TableCell>
              <TableCell>{draft.route}</TableCell>
              <TableCell>{draft.manager}</TableCell>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal open={loading} onClose={() => setLoading(false)} center>
        <div className="p-24">
          <ClipLoader size={50} color={"#3b82f6"} loading={true} />
        </div>
      </Modal>
      <ConfirmActionModal
        visible={toDeleteDraft != null}
        question="Você têm certeza que deseja apagar o rascunho ? (Essa alteração não poderá ser desfeita!)"
        onCancel={() => setToDeleteDraft(null)}
        onConfirm={() => handleDraftDelete(toDeleteDraft)}
      />
    </motion.div>
  );
}