import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import React, { useEffect, useState } from "react";
import CreateListItemButton from "../CreateListItemButton";
import { Participant } from "../../../../types/EventData";
import ParticipantsListItem from "../ParticipantsListItem";
import reorderArray from "../../../../utils/helpers/reorderArray";
import getMaxIndexByIdSuffix from "../../../../utils/helpers/getMaxIndexByIdSufix";
import InsertListButton from "../../../../utils/ui/InsertListButton";

interface ParticipantsListProps {
  participants: Participant[];
  small?: boolean;
  height?: number;
  customIdPrefix?: string;
  onSelect: (selectedId: string) => void;
  onDelete: (categoryUpdate: Participant[]) => void;
  onCreate: (categoryUpdate: Participant[]) => void;
  onDrag: (categoryUpdate: Participant[]) => void;
}

function ParticipantsList({participants, onDelete, onCreate, onDrag, onSelect, height, small, customIdPrefix}: ParticipantsListProps) {
  const [selectedId, setSelectedId] = useState<string>('');
  
  useEffect(() => {
    onSelect(selectedId);
  }, [selectedId])

  const onDragEnd = (result: DropResult<string>) => {
    if (!result.destination) {
      return;
    }
    const swipedItemsArray = reorderArray<Participant>(participants, result.source.index, result.destination.index);
    
    onDrag(swipedItemsArray);
  }

  const handleParticipantDelete = (participantId: string) => {
    const categoryUpdate = participants.filter(participant => participant.id !== participantId);
    setSelectedId('');

    onDelete(categoryUpdate);
  }

  const handleParticipantClick = (participantId: string) => {
    if (participantId == selectedId) {
      return setSelectedId('');
    }
    console.log(participantId)
    setSelectedId(participantId);
  }

  const handleParticipantCreate = () => {
    const participantUpdate = [...participants];
    participantUpdate.push({
      id: `${customIdPrefix ? customIdPrefix : 'newpct'}-${getMaxIndexByIdSuffix(participantUpdate, 'newpct') + 1}`,
      name: '<Novo Participante>',
      description: '',
      cpf: '',
      profilepic: 'https://image-cdn-ak.spotifycdn.com/image/ab67706c0000da8463bcdace67f79859e30a17fa',
      nationality: '',
      isspeaker: false,
      introductionauthor: false,
      // category: undefined,
      categories: []
    });

    onCreate(participantUpdate);
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categories" type="list" direction="vertical">
          {(provided) => (
            <ul 
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`border-r-transparent xl:border-r-slate-200 border-l-transparent border-t-transparent xl:border-b-transparent pe-8 border-4 overflow-y-scroll h-[300px]`}
              style={{
                width: small ? '400px' : '600px',
                // maxHeight: small ? '100px' : '300px'
              }}
            >
              {participants.length == 0 && <li style={{width: '26.65rem'}} className="h-12 w-full"></li>}
              {participants.map((participant, index) => (
                <ParticipantsListItem 
                  key={participant.id}
                  participant={participant}
                  selected={selectedId == participant.id}
                  small={small}
                  index={index}
                  handleDelete={handleParticipantDelete}
                  onClick={handleParticipantClick}
                />
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-center items-center mt-6">
        {/* <CreateListItemButton onClick={handleParticipantCreate} /> */}
        <InsertListButton onClick={handleParticipantCreate} />
      </div>
    </div>
  );
}

export default ParticipantsList;