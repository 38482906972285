import React from "react";

type TextContainerProps = {
  title: string;
  titleCenter?: boolean;
  content: string[];
  authorName?: string;
}

function TextContainer({title, content, authorName, titleCenter}: TextContainerProps) {
  return (
    <div className="textContainer h-auto"> {/* Garante que o contêiner tenha altura automática */}
      <div className="flex flex-col">
        <h2 className={`font-bold ${titleCenter ? 'text-center md:text-4xl mb-6' : 'text-3xl mb-2'} ml-1`}>
          {title}
        </h2>
        <div>
          {content.map((phrase: string, index: number) => (
            <p key={index} className='mb-6 md:text-base sm:text-sm text-wrap break-words text-justify'> {/* Aplica alinhamento justificado ao texto */}
              {phrase}
            </p>
          ))}
        </div>
        {authorName && (
          <h3 className="font-bold text-md self-end mt-4"> {/* Adiciona margem superior para separação */}
            {authorName}
          </h3>
        )}
      </div>
    </div>
  );
}

export default TextContainer;