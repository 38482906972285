import { motion, AnimatePresence } from "framer-motion";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import EventManagerProgress from "../components/EventManagerProgress";
import Info from "../components/EventManagerProgress/Steps/Info";
import Details from "../components/EventManagerProgress/Steps/Details";
import ParticipantsManager from "../components/EventManagerProgress/Steps/ParticipantsManager";
import Sponsors from "../components/EventManagerProgress/Steps/Sponsors";
import Settings from "../components/EventManagerProgress/Steps/Settings";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { TbLivePhotoFilled } from "react-icons/tb";
import { IoClose } from "react-icons/io5";
import { FaToolbox } from "react-icons/fa";
import BlockDiv from "../utils/ui/BlockDiv";
import { EventData, Layout } from "../types/EventData";
import { parseEventDataToCreateEventData } from "../utils/parser/parseEventDataToCreateEventData";
import { deleteDraftById, fetchCreateEvent, fetchDraftData, fetchEditEvent, fetchEventEditData, generateDraftId, livePreviewSocketUrl, updateDraftById, updateLivePreviewData } from "../utils/api";
import Subscriptions from "../components/EventManagerProgress/Steps/Subscriptions";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Bounce, toast, ToastContainer } from 'react-toastify';
import FeedbackModal from "../components/EventManagerProgress/FeedbackModal";
import { recursiveKeyUpdate } from "../utils/helpers/recursiveKeyUpdate";
import Schedule from "../components/EventManagerProgress/Steps/Schedule";
import Expositors from "../components/EventManagerProgress/Steps/Expositors";
import Categories from "../components/EventManagerProgress/Steps/Categories";
import Functions from "../components/EventManagerProgress/Steps/Functions";
import Loading from "./Loading";
import formatTime from "../utils/helpers/formatTime";
import { parseDraftData } from "../utils/parser/parseDraftData";
import { Tooltip } from "react-tooltip";
import { io, Socket } from "socket.io-client";

const stateComponents = [
  Info,
  Details,
  Categories,
  ParticipantsManager,
  Functions,
  Schedule,
  Sponsors,
  Subscriptions,
  Expositors,
  Settings
];

const variants = {
  enter: (direction: number) => {
    return {
      x: direction === 0 ? 500 : -500,
      opacity: 0
    };
  },
  center: {
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      x: direction === 0 ? -500 : 500,
      opacity: 0
    };
  }
};

enum EventCreationStatus {
  none = -1,
  loading = 0,
  success = 1,
  error = 2
}

type EventManagerProps = {
  isDraft?: boolean;
}

function EventManager({
  isDraft
}: EventManagerProps) {
  const { layoutName, eventId, draftId } = useParams();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [direction, setDirection] = useState<number>(0);
  const [statusModelOpen, setStatusModalOpen] = useState(false);
  const [toolboxOpen, setToolboxOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eventCreationStatus, setEventCreationStatus] = useState<EventCreationStatus>(EventCreationStatus.none);
  const [eventCreationText, setEventCreationText] = useState<string>('');

  const [lastDraftUpdate, setLastDraftUpdate] = useState<number>(new Date().getTime());
  const [eventUpdated, setEventUpdated] = useState<boolean>(false);
  const [isDraftUpdated, setIsDraftUpdated] = useState<boolean>(false);
  const [currentDraftId, setCurrentDraftId] = useState<number | undefined>(draftId ? parseInt(draftId) : undefined);

  const [roomUuid, setRoomUuid] = useState<string | null>(null);

  const socketRef = useRef<Socket | null>(null);

  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [eventData, setEventData] = useState<EventData>({
    layout: (layoutName as Layout),
    draftid: undefined,
    is_draft: false,
    logourl: '',
    banner: {
      backgroundimgs: [],
      title: ''
    },
    introduction: [],
    participants: [],
    functions: [],
    place: {
      name: '',
      district: '',
      state: '',
      cep: '',
      number: '',
      complement: '',
      reference: '',
      telephone: '',
      street: '',
      description: '',
      city: '',
      youtubeurl: '',
      imgs: []
    },
    sponsors: [],
    categories: [],
    expositor: {
      manuallink: '',
      expositors: [],
      name: '',
      telephones: [],
      email: ''
    },
    schedule: {
      days: []
    },
    startdate: new Date(),
    paymentdate: new Date(),
    subscriptioncategories: [],
    color1: [0, 0, 0],
    color2: [0, 0, 0],
    route: ''
  });

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    if (isDraft) {
      loadDraftData();
    }
    
    loadData();

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!roomUuid) {
      return;
    }

    updateLivePreviewData(roomUuid, eventData);
  }, [eventData]);

  const loadData = async () => {
    let newDraftId: number | undefined = currentDraftId;

    if (!newDraftId) {
      newDraftId = await generateDraftId();
      setCurrentDraftId(newDraftId);
      setEventData((prev) => ({...prev, draftid: newDraftId, is_draft: true}));
    }

    if (eventId) {
      setIsLoading(true);
      const data = await fetchEventEditData(parseInt(eventId));

      setIsLoading(false);
      setIsEditing(true);

      setEventData(data);
      if (newDraftId) {
        data.draftid = newDraftId;
        data.is_draft = true;
        updateDraftById(newDraftId, JSON.stringify(parseDraftData(data)));
      }
    }
  }

  const loadDraftData = async () => {
    if (!draftId) {
      return;
    }

    setIsLoading(true);
    const draft = await fetchDraftData(draftId);
    setIsLoading(false);
    setEventData(draft);
  }

  const handleStepChange = (step: number) => {
    handleDraftUpdate({notify: true});
    setDirection(step > currentStep ? 0 : 1);
    setCurrentStep(step);
  };

  const onOpenModal = () => setStatusModalOpen(true);
  const onCloseModal = async () => {
    setStatusModalOpen(false);

    if (eventUpdated && eventData.draftid) {
      await deleteDraftById(eventData.draftid);
      navigate('/events');
    }
  };

  const handleFormChange = (data: {[key: string]: any}) => {
    setEventData(prevState => {
      const newState = {...prevState};
      recursiveKeyUpdate(newState, data);
      return newState;
    });

    console.log("atualizando draft")
    handleDraftUpdate({notify: false});
  }

  const handleDraftUpdate = async ({notify}: {notify: boolean}) => {
    if (currentDraftId) {
      if (isLoading || (eventData.eventid == 0 && isEditing)) {
        return;
      }

      const parsedDraftData = parseDraftData(eventData);

      parsedDraftData.draftid = currentDraftId;
      parsedDraftData.is_draft = true;

      updateDraftById(currentDraftId, JSON.stringify(parsedDraftData));
      notify && toast.success('Rascunho salvo com sucesso!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLastDraftUpdate(new Date().getTime());
      setIsDraftUpdated(true);
    }
  }

  const handleFormSubmit = async () => {
    try {
      onOpenModal();
      setEventCreationStatus(EventCreationStatus.loading);

      const route = eventData.route?.replace('/', '');

      const fetchData = JSON.stringify(parseEventDataToCreateEventData(eventData));
      console.log(fetchData)

      if (isEditing || eventData.eventid) {
        await fetchEditEvent(fetchData, eventId || eventData.eventid!.toString());
        setEventCreationText(`Acesse seu evento editado em <a href="/${route}">/${route}</a>`);
      } else {
        await fetchCreateEvent(fetchData);
        setEventCreationText(`Acesse seu novo evento em <a href="/${route}">/${route}</a>`);
      }
      setEventUpdated(true);
      setEventCreationStatus(EventCreationStatus.success);
    } catch(errorMsg) {
      console.log(errorMsg)
      setEventCreationText(errorMsg as string)
      setEventCreationStatus(EventCreationStatus.error);
    }
  }

  const handleLivePreviewRedirect = () => {
    if (roomUuid) {
      // navigate("/live/" + roomUuid);
      window.open("/live/" + roomUuid, "_blank");
      return;
    }

    socketRef.current = io(livePreviewSocketUrl, {});
    socketRef.current.emit("create_room", JSON.stringify(eventData));

    socketRef.current.on("created_room", (data: any) => {
      console.log(data);
      setRoomUuid(data.room);
      // navigate("/live/" + data.room);
      window.open("/live/" + data.room, "_blank");
    });
  }

  const renderStep = (): ReactNode => {
    const StateComponent = stateComponents[currentStep];
    if (!StateComponent) {
      return <div>Invalid step</div>;
    }

    return (
      <motion.div
        key={currentStep}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        custom={direction}
        transition={{
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.1 }
        }}
      >
        <div className="fixed flex items-center justify-between px-4 w-screen h-screen pointer-events-none">
          {currentStep > 0 ? (
            <RiArrowLeftLine
            className="pointer-events-auto cursor-pointer transform transition transition-duration-300 hover:scale-110"
            onClick={() => handleStepChange(currentStep - 1)}
            style={{width: '50px', height: '50px'}}
          />) : (<BlockDiv />)}
          {currentStep < stateComponents.length - 1 ? ( <RiArrowRightLine
            className="pointer-events-auto cursor-pointer transform transition transition-duration-300 hover:scale-110"
            onClick={() => handleStepChange(currentStep + 1)}
            style={{width: '50px', height: '50px'}}
            />) : (<BlockDiv />)}
        </div>
        <StateComponent
          eventData={eventData}
          handleFormChange={handleFormChange}
          submitForm={handleFormSubmit}
          isEditing={isEditing}
        />
      </motion.div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="EventManager bg-white 2xl:overflow-hidden relative"
      style={{
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="absolute top-0 left-0 p-8 hover:scale-105 transition-all duration-300">
        <a 
          data-tooltip-id="goback-btn"
          data-tooltip-content="Sair"
          href="/events"
        >
          <FaArrowLeftLong style={{width: '35px', height: '35px'}} />
          <Tooltip
            id="goback-btn"
          />
        </a>
      </div>
      <div className="absolute top-0 right-0 p-8 hover:scale-105 transition-all duration-300">
        <button
          onClick={() => setToolboxOpen(true)}
          data-tooltip-id="toolbox-btn"
          data-tooltip-content="Caixa de ferramentas"
        >
          <FaToolbox style={{width: '35px', height: '35px'}} />
          <Tooltip 
            id="toolbox-btn"
          />
        </button>
      </div>
      <nav className="flex flex-col gap-2 justify-center items-center pt-6 pb-2 mb-2">
        <h1 className="font-bold text-4xl">Configure o Layout</h1>
        <div className="flex flex-col gap-2">
          <p className={`${isDraftUpdated ? 'text-black/40' : 'text-transparent'} font-bold text-sm`}>Ultimo rascunho salvo às {formatTime(new Date(lastDraftUpdate))}</p>
          <button
            className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-lg transition transition-duration-300"
            onClick={() => handleDraftUpdate({notify: true})}
          >
            Salvar rascunho
          </button>
        </div>
      </nav>
      <div className="relative">
        <EventManagerProgress
          currentStep={currentStep}
          stepChange={handleStepChange}
        />
        <AnimatePresence mode="wait" custom={direction}>
          {renderStep()}
        </AnimatePresence>
      </div>

      <Modal open={isLoading} closeIcon={false} onClose={() => navigate('/events')} center closeOnEsc={false} closeOnOverlayClick={false}>
        <div className="w-96 h-96 max-w-96 max-h-96 flex flex-col items-center justify-center">
          <Loading />
        </div>
      </Modal>

      <Modal open={statusModelOpen} onClose={onCloseModal} center>
        <div className="w-96 h-96 max-w-96 max-h-96 flex flex-col items-center justify-center">
          <h2
            className={`text-center mt-12 text-4xl font-bold ${eventCreationStatus == EventCreationStatus.error ? 'text-red-500' : (eventCreationStatus == EventCreationStatus.loading ? 'text-blue-500' : 'text-green-500')}`}
          >
            {(isEditing || eventData.eventid) ? 'Editando evento' : 'Criando evento'}
          </h2>
          <FeedbackModal
            eventCreationStatus={eventCreationStatus}
            message={eventCreationText}
          />
        </div>
      </Modal>

      <AnimatePresence>
        {toolboxOpen && (
          <motion.div
            initial={{x: 600}}
            animate={{x: 0}}
            exit={{x: 600}}
            transition={{duration: 0.8, ease: "easeOut"}}
            key="toolbox" 
            className="absolute flex flex-col top-0 right-0 h-full bg-[#eeeeee] w-96 z-20 shadow-xl px-8"
          >
            <button onClick={() => setToolboxOpen(false)} className="py-8 hover:scale-105 transition-all duration-300">
              <IoClose color="#000" size={32} />
            </button>
            <ul className="flex-1">
              <li>
                <button
                  // href="/live/23d4f3"
                  // target="_blank"
                  onClick={handleLivePreviewRedirect}
                  className="w-full flex items-center justify-center gap-2 bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-lg transition transition-duration-300"
                >
                  <TbLivePhotoFilled size={20} />
                  Live Event View
                </button>
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
      <ToastContainer />
    </motion.div>
  );
}

export default EventManager;
