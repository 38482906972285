import React from "react";

import { RiPencilFill } from "react-icons/ri";
import { GoPersonFill } from "react-icons/go";
import { FaPeopleGroup } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";
import IconCircle, { IconCircleColors } from "./IconCircle";
import LinkText from "./LinkText";
import LinkItem from "./LinkItem";
import ColumnList from "./ColumnList";
import RowList from "./RowList";

type QuickLinksProps = {
  eventSlug?: string;
  horizontal?: boolean;
  preview?: boolean;
  onPreviewRedirect?: (route: string) => void;
}

function QuickLinks({
  horizontal, 
  eventSlug,
  preview,
  onPreviewRedirect
}: QuickLinksProps) {
  const QuickLinkItems = () => (
    <>
      <LinkItem 
        href={`/${eventSlug}/subscription`} 
        icon={RiPencilFill} 
        color={IconCircleColors.Orange} 
        content="FAÇA SUA INSCRIÇÃO"
        preview={preview}
        onClick={() => onPreviewRedirect && onPreviewRedirect("subscriptions")}
      />
      <LinkItem 
        href={`/${eventSlug}/guests`} 
        icon={GoPersonFill} 
        color={IconCircleColors.LightOrange} 
        content="CONFIRA OS PALESTRANTES CONVIDADOS" 
        preview={preview}
        onClick={() => onPreviewRedirect && onPreviewRedirect("guests")}
      />
      <LinkItem 
        href={`/${eventSlug}/comissions`} 
        icon={FaPeopleGroup} 
        color={IconCircleColors.Blue} 
        content="CONHEÇA OS MEMBROS DA COMISSÃO" 
        preview={preview}
        onClick={() => onPreviewRedirect && onPreviewRedirect("comissions")}
      />
      <LinkItem 
        href={`/${eventSlug}/schedule`} 
        icon={SlCalender} 
        color={IconCircleColors.LightBlue} 
        content="VERIFIQUE A AGENDA DO EVENTO" 
        preview={preview}
        onClick={() => onPreviewRedirect && onPreviewRedirect("schedule")}
      />
    </>
  )

  return (
    <div className="quickLink">
      {(horizontal ? (
        <RowList>
          <QuickLinkItems />
        </RowList>
      ) : (
        <ColumnList>
          <QuickLinkItems />
        </ColumnList>
      ))}
    </div>
  );
}

export default QuickLinks;