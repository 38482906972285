import React, { useEffect, useState } from "react";

import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { useFetchDemoData } from "../../hooks/useFetchDemoData";

import Layout1 from "./Layout1";
import Layout2 from "./Layout2";
import Layout3 from "./Layout3";
import Layout4 from "./Layout4";
import Comissions from "../Comissions";
import Guests from "../Guests";
import Expositors from "../Expositors";
import Subscriptions from "../Subscriptions";
import Schedule from "../Schedule";
import Support from "../Support";
import Loading from "../Loading";
import Error from '../Error';
import SubscriptionPayment from "../SubscriptionPayment";

const routesComponentMap: Record<string, React.ComponentType<any>> = {
  support: Support,
  layout1: Layout1,
  layout2: Layout2,
  layout3: Layout3,
  layout4: Layout4
}

function Layout() {
  const { eventSlug } = useParams();

  const {data, loading, error} = useFetchDemoData();

  if (loading) {
    return (<Loading />);
  }

  if (error) {
    return (<Error />);
  }

  const LayoutComponent = routesComponentMap[data?.layout || 'layout1'];

  return (
    <Routes>
      <Route path="support" element={<Support eventSlug={eventSlug} />} />
      <Route path="subscriptions" element={<Subscriptions eventSlug={eventSlug} />} />
      <Route path="schedule" element={<Schedule eventSlug={eventSlug} />} />
      <Route path="guests" element={<Guests eventSlug={eventSlug} />} />
      <Route path="expositors" element={<Expositors eventSlug={eventSlug} />} />
      <Route path="comissions" element={<Comissions eventSlug={eventSlug} />} />
      <Route path="subscriptions/payment" element={<SubscriptionPayment eventSlug={eventSlug} />} />
      <Route path="/*" element={<LayoutComponent eventSlug={eventSlug} />} />
    </Routes>
  );
}

export default Layout;