//@ts-nocheck
function isValidDateFormat(dateString: string) : boolean {
  //2023-10-01T12:00:00
  // const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3}Z)?$/;
  //2024-10-10T12:00:00.000Z
  // const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  return regex.test(dateString);
}

function parseResponseTypes(response : Object) {
  for (const [key, value] of Object.entries(response)) {
    if (!value) {
      continue;
    }
    if (typeof value == "object") {
      parseResponseTypes(value);
    }

    if (isValidDateFormat(value)) {
      response[key] = new Date(value);
    }
  }
}

export default parseResponseTypes;