import React from "react";
import { IconType } from "react-icons"
import IconCircle, { IconCircleColors } from "../IconCircle";
import LinkText from "../LinkText";

type LinkItemProps = {
  icon : IconType;
  color : IconCircleColors;
  content : string;
  href?: string;
  preview?: boolean;
  onClick?: () => void;
}

function LinkItem({
  icon, 
  color, 
  content, 
  href,
  preview,
  onClick
} : LinkItemProps) {
  return (
    <li>
      <a
        href={preview ? undefined : href}
        onClick={onClick}
        className="flex justify-between items-center gap-5 transition duration-300 transform hover:scale-105 hover:-translate-y-1 shadow hover:shadow-lg cursor-pointe p-4 cursor-pointer"
      >
        <IconCircle icon={icon} color={color} />
        <LinkText>{content}</LinkText>
      </a>
    </li>
  );
}

export default LinkItem;