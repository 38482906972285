import React, { useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"

import '../../../styles/speakerCarousel.css';
import { Participant } from "../../../types/EventData";
import ProfileBadge from "../../ProfileBadge";

type SpeakerCarouselData = {
  authors : Participant[];
  color1: number[];
}

function SpeakerCarousel({ authors, color1 } : SpeakerCarouselData) {
  const [swiperControl, setSwiperControl] = useState<SwiperClass>();

  return (
    <div 
      className="m-auto w-full max-w-[800px] px-24 relative"
    >
      {(authors.length > 2) ? (
        <Swiper
          onSwiper={(swiper: SwiperClass) => setSwiperControl(swiper)}
          className="speaker-slider"
          direction="horizontal"
          // slidesPerView={windowSize.width < 1800 ? 2 : 3}
          slidesPerView={2}
          spaceBetween={-180}
          navigation={true}
          centeredSlides={true}
          initialSlide={Math.floor(authors.length / 2)}
          modules={[Navigation]}
        >
          {authors.map(author => (
            <SwiperSlide className="flex-shrink-0" key={author.id}> 
              <ProfileBadge profile={author} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="flex items-center justify-center gap-24">
          {authors.map(author => (
            <div>
              <ProfileBadge profile={author} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SpeakerCarousel;