import React from "react";
import { Participant } from "../../types/EventData";
import Modal from "react-responsive-modal";
import ReactCountryFlag from "react-country-flag";
import { getNationalities } from "../../utils/helpers/getNationalities";

interface CurriculumModalProps {
  participant: Participant;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}

export default function CurriculumModal({ 
  participant,
  isModalOpen,
  setIsModalOpen,
}: CurriculumModalProps) {
  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      center
      classNames={{
        overlay: 'bg-black/50',
        modal: 'w-full max-w-3xl'
      }}
    >
      <div className="bg-white p-8 flex flex-col items-center">
        <div className="flex flex-col md:flex-row gap-8 max-w-2xl">
          {/* Left side - Image and basic info */}
          <div className="flex flex-col items-center">
            <img 
              src={participant?.profilepic} 
              alt={participant?.name}
              className="profile-pic mb-4 shadow-lg"
            />
            <h2 className="text-2xl font-bold mb-2 text-center">{participant?.name}</h2>
            {/* {participant?.category && (
              <p className="text-gray-600 font-medium mb-2 text-center">{participant.category.name}</p>
            )} */}
            {participant?.nationality && (
              <div className="flex items-center gap-2">
                <ReactCountryFlag 
                  countryCode={participant.nationality} 
                  svg 
                  style={{
                    width: '24px',
                    height: '24px'
                  }}
                />
                <span className="text-gray-600">
                  {getNationalities().find(n => n.code === participant.nationality)?.name}
                </span>
              </div>
            )}
          </div>

          {/* Right side - Description */}
          <div className="flex-1">
            <h3 className="text-xl font-semibold mb-4 text-center">Mini Currículo</h3>
            <div className="bg-gray-50 p-6 rounded-lg" style={{ maxHeight: '300px' }}>
              <div className="overflow-y-auto max-h-[250px] pr-2">
                {participant?.description ? (
                  <p className="text-gray-700 whitespace-pre-wrap leading-relaxed" style={{ overflowWrap: 'break-word' }}>
                    {participant.description}
                  </p>
                ) : (
                  <p className="text-gray-400 italic">
                    Nenhuma informação adicional disponível.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}