import React, { useEffect, useState } from "react";
import ProgrammingList from "../ProgrammingList";
import { FunctionData, Participant, ScheduleProgramming } from "../../../../../types/EventData";
import TextInput from "../../TextInput";
import ListItem from "../../../../../utils/ui/ListItem";
import { FaCheck } from "react-icons/fa6";
import timeStringToMinutes from "../../../../../utils/helpers/timeStringToMinutes";
import minutesToTimeString from "../../../../../utils/helpers/minutesToTimeString";

interface ProgrammingFormProps {
  programming: ScheduleProgramming;
  eventParticipants: Participant[];
  functions: FunctionData[];
  selectedParticipant?: Participant;
  onProgrammingChange: (key: keyof ScheduleProgramming, value: any) => void;
  onParticipantSelect: (participant: Participant) => void;
  onAssignmentChange: (participantId: string, functionId: string | undefined) => void;
}

export default function ProgrammingForm({
  programming,
  eventParticipants,
  functions,
  selectedParticipant,
  onProgrammingChange,
  onParticipantSelect,
  onAssignmentChange
}: ProgrammingFormProps) {
  const [duration, setDuration] = useState<string>(minutesToTimeString(programming.minutes));

  useEffect(() => {
    setDuration(minutesToTimeString(programming.minutes));
  }, [programming]);

  // const sortedParticipants = eventParticipants.slice().sort((a, b) => {
  //   const isParticipantA = programming.participants.some(participant => participant.id === a.id) ? 1 : 0;
  //   const isParticipantB = programming.participants.some(participant => participant.id === b.id) ? 1 : 0;
  //   return isParticipantB - isParticipantA;
  // });

  return (
    <div className="flex flex-col gap-4">
      <TextInput
        title="Tema da Programação"
        value={programming.theme}
        placeholder="DIGITE O TEMA DA PROGRAMAÇÃO"
        onChange={(theme) => onProgrammingChange("theme", theme)}
      />
      <div>
        <p className="text-2xl font-bold mb-4">Duração</p>
        <input 
          className="border-2 rounded-full border-gray-300 text-center px-2 py-1"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          // onChange={console.log}
          onBlur={() => onProgrammingChange("minutes", timeStringToMinutes(duration))}
          type="time" 
          min="00:00"
          max="23:59" 
        />
      </div>
      <div>
        <p className="text-2xl font-bold inline-block me-2">Intervalo</p>
        <input 
          type="checkbox"
          checked={programming.isinterval}
          onChange={(e) => onProgrammingChange("isinterval", e.target.checked)}
        />
      </div>
      {programming.isinterval ? (
        <p className="text-gray-300 font-semibold">Programação definida como intervalo</p>
      ) : (
        <div>
          <p className="text-2xl font-bold mb-2">Participantes</p>
          {eventParticipants.length == 0 && (
            <p className="text-center text-gray-300 font-semibold">
              Você ainda não têm nenhuma pessoa adicionada ao evento. Crie no gerenciamento de pessoas!
            </p>
          )}
          <ul
            className="h-28 w-[400px] overflow-y-scroll"
          >
            {/* {sortedParticipants.map((participant) => { */}
            {eventParticipants.map((participant) => {
              // const isParticipant = programming.participants.some((p) => p.id == participant.id);
              return (
                <ListItem
                  className="cursor-pointer flex items-center gap-4 px-4 py-1"
                  selected={selectedParticipant?.id === participant.id}
                  onClick={() => onParticipantSelect && onParticipantSelect(participant)}
                >
                  <img
                    className="w-8 h-8 rounded-full"
                    src={participant.profilepic}
                  />
                  <div className="flex flex-col">
                    <p>{participant.name}</p>
                    {/* {participant.category && (
                      <span className="text-xs text-gray-500">
                        {participant.category.name}
                      </span>
                    )} */}
                  </div>
                  {programming.assignments.find(assignment => assignment.participant.id === participant.id)?.function && (
                    <span className="text-xs text-gray-500 ml-2">
                      • {programming.assignments.find(assignment => assignment.participant.id === participant.id)?.function.name}
                    </span>
                  )}
                  {/* <FaCheck 
                    className={`${isParticipant ? '' : 'invisible'} ml-auto`} 
                    color="#FFF" 
                  /> */}
                </ListItem>
              );
            })}
          </ul>
          {selectedParticipant && (
            <div className="flex flex-col gap-4 mt-8">
              <p className="text-lg font-bold">Função para {selectedParticipant?.name}</p>
              <select
                className="border rounded px-2 py-1"
                onChange={(e) => {
                  const selectedFunctionId = e.target.value;
                  onAssignmentChange(selectedParticipant.id, selectedFunctionId || undefined); 
                }}
                value={programming.assignments.find(assignment => 
                  assignment.participant.id === selectedParticipant.id
                )?.function.id || ''}
              >
                <option value="">{"<Sem função>"}</option>
                {functions.map((func) => (
                  <option value={func.id}>{func.name}</option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}
    </div>
  );
}