import { Category, EventData, Participant, ScheduleDay, SchedulePlace, SponsorCategory, SubscriptionCategory, Assignment } from "../../types/EventData";

export function parseEventDataToCreateEventData(eventData: EventData) {
  let idCounter = 1;

  const getNewId = (oldId: any, map: Record<string, number>) => {
    if (!map[oldId]) {
      map[oldId] = idCounter++;
    }
    return map[oldId];
  };

  const participantIdMap: Record<string, number> = {};
  const categoryIdMap: Record<string, number> = {};
  const sponsorCategoryIdMap: Record<string, number> = {};
  const sponsorIdMap: Record<string, number> = {};
  const placeImgIdMap: Record<string, number> = {};
  const bannerImgIdMap: Record<string, number> = {};
  const dayIdMap: Record<string, number> = {};
  const placeIdMap: Record<string, number> = {};
  const moduleIdMap: Record<string, number> = {};
  const programmingIdMap: Record<string, number> = {};
  const subscriptionCategoryIdMap: Record<string, number> = {};
  const functionIdMap: Record<string, number> = {};

  let allAssignments: {id: number, participantid: number, functionid: number}[] = [];
  let assignmentIdCounter = 1;

  const categories = eventData.categories.map((category: Category) => ({
    id: getNewId(category.id, categoryIdMap),
    guestcategory: category.guestcategory,
    name: category.name
  }));

  if (!eventData.route || eventData.route.replace(' ', '/') == "") {
    throw "A rota do evento está indefinida."
  }

  if (!eventData.startdate) {
    throw "A data de início do evento está indefinida."
  }

  if (!eventData.paymentdate) {
    throw "A data limite de pagamento está indefinida."
  }

  if (!eventData.banner?.title) {
    throw "O título do banner está indefinido."
  }

  if (!eventData.place?.name) {
    throw "O nome do local do evento está indefinido."
  }

  if (!eventData.schedule?.days?.length) {
    throw "A programação do evento está indefinida."
  }

  if (!eventData.layout) {
    throw "O layout do evento está indefinido."
  }

  if (!eventData.color1 || !eventData.color2) {
    throw "As cores do evento estão indefinidas."
  }

  if (!eventData.logourl) {
    throw "O logo do evento está indefinido."
  }

  // if (!eventData.expositor?.name) {
  //   throw "O nome do expositor está indefinido."
  // }

  if (!eventData.expositor?.email) {
    throw "O email do expositor está indefinido."
  }

  if (!eventData.functions?.length) {
    throw "As funções do evento estão indefinidas."
  }

  if (!eventData.subscriptioncategories?.length) {
    throw "As categorias de inscrição estão indefinidas."
  }

  // Validação do local do evento
  if (!eventData.place?.street || !eventData.place?.number || !eventData.place?.city || !eventData.place?.state) {
    throw "O endereço do local do evento está incompleto."
  }

  // Validação das imagens do banner
  if (!eventData.banner?.backgroundimgs?.length) {
    throw "As imagens do banner estão indefinidas."
  }

  const participants = eventData.participants.map((participant: Participant) => {
    if (!participant.name || participant.name.trim() === '') {
      throw `Existe um participante sem nome!`
    }

    // if (!participant.category) {
    //   throw `O participante ${participant.name} está sem categoria!`
    // }

    if (participant.isspeaker && !participant.description) {
      throw `O participante ${participant.name} é um palestrante mas está sem descrição!`
    }
    
    return {
      id: getNewId(participant.id, participantIdMap),
      name: participant.name,
      description: participant.description,
      cpf: participant.cpf,
      profilepic: participant.profilepic,
      isspeaker: participant.isspeaker,
      // categoryid: categoryIdMap[!participant.category ? -1 : participant.category!.id || participant.categoryid!],
      // categoryname: !participant.category ? '' : participant.category!.name,
      categoryid: 0,
      categoryname: "vou remover",
      categories: participant.categories.map((category) => {
        console.log(category)
        return {
          id: 0,
          categoryid: categoryIdMap[category.id] || -1,
          participantid: 0
        }
      }),
      introductionauthor: participant.introductionauthor,
      nationality: participant.nationality,
      index: participant.index
    };
  });

  const sponsorCategories = eventData.sponsors.map((sponsorCategory: SponsorCategory) => ({
    id: getNewId(sponsorCategory.id, sponsorCategoryIdMap),
    name: sponsorCategory.name,
    index: sponsorCategoryIdMap[sponsorCategory.id]
  }));

  const sponsors = eventData.sponsors.map((sponsorCategory: SponsorCategory) => {
    if (!sponsorCategory.name || sponsorCategory.name.trim() === '') {
      throw "Existe uma categoria de patrocinador sem nome!"
    }

    return {
      name: sponsorCategory.name,
      participants: sponsorCategory.participants.map((sponsor: any) => {
        if (!sponsor.name || sponsor.name.trim() === '') {
          throw `Existe um patrocinador sem nome na categoria ${sponsorCategory.name}!`
        }

        const uniqueKey = `${sponsorCategory.id}-${sponsor.id}`;
        return {
          id: getNewId(uniqueKey, sponsorIdMap),
          name: sponsor.name,
          pic: sponsor.pic,
          index: sponsorIdMap[uniqueKey],
          sponsorcategoryid: sponsorCategoryIdMap[sponsorCategory.id]
        };
      })
    };
  });
  
  // Map imgs in place and assign IDs
  console.log("place imgs");
  console.log(eventData.place.imgs)
  const placeImgs = eventData.place.imgs!.map((img: any) => ({
    id: getNewId(img.id || img.src, placeImgIdMap), // Use img.src as key if id is missing
    src: img.src,
    alt: img.alt
  }));

  // Map backgroundimgs in banner and assign IDs
  const backgroundimgs = eventData.banner.backgroundimgs.map((img: any) => ({
    id: getNewId(img.id || img.src, bannerImgIdMap),
    src: img.src,
    alt: img.alt
  }));

  const scheduleDays = eventData.schedule.days.map((day: ScheduleDay, index: number) => {
    if (!day.date) {
      throw "Existe um dia na programação sem data definida!"
    }

    const dayId = getNewId(day.id || index, dayIdMap);
    const places = day.dayplaces.map((place: SchedulePlace) => {
      if (!place.title || place.title.trim() === '') {
        throw `Existe um local sem título no dia ${new Date(day.date).toLocaleDateString()}!`
      }

      const placeId = getNewId(place.id, placeIdMap);
      const modules = place.placemodules.map((module) => {
        if (!module.title || module.title.trim() === '') {
          throw `Existe um módulo sem título no local ${place.title}!`
        }

        const moduleId = getNewId(module.id, moduleIdMap);
        const programming = module.moduleprogrammings.map((programming) => {
          if (!programming.theme || programming.theme.trim() === '') {
            throw `Existe uma programação sem tema no módulo ${module.title}!`
          }

          if (!programming.isinterval && programming.minutes <= 0) {
            throw `Existe uma programação com duração inválida no módulo ${module.title}!`
          }

          const programmingId = getNewId(programming.id, programmingIdMap);
          const assignmentIds = programming.assignments.map((assignment) => {
            const assignmentId = assignmentIdCounter++;
            allAssignments.push({
              id: assignmentId,
              participantid: participantIdMap[assignment.participant.id],
              functionid: getNewId(assignment.function.id, functionIdMap)
            });
            return assignmentId;
          });
          return {
            id: programmingId,
            minutes: programming.minutes,
            isinterval: programming.isinterval,
            theme: programming.theme,
            assignments: assignmentIds.map((id) => ({id}))
          }
        });
        const moduleAssignmentIds = module.assignments.map((assignment) => {
          const assignmentId = assignmentIdCounter++;
          allAssignments.push({
            id: assignmentId,
            participantid: participantIdMap[assignment.participant.id],
            functionid: getNewId(assignment.function.id, functionIdMap)
          });
          return assignmentId;
        });
        return {
          id: moduleId,
          title: module.title,
          independent: module.independent,
          startime: new Date(module.startime.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace(/\.\d{3}Z$/, ''),
          endtime: new Date(module.endtime.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace(/\.\d{3}Z$/, ''),
          moduleprogrammings: programming,
          assignments: moduleAssignmentIds.map((id) => ({id}))
        }
      });
      return {
        id: placeId,
        title: place.title,
        date: new Date(place.date.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace(/\.\d{3}Z$/, ''),
        placemodules: modules
      }
    });
    return {
      id: dayId,
      date: new Date(day.date.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace(/\.\d{3}Z$/, ''),
      index: index + 1,
      dayplaces: places
    }
  });

  // Map subscription categories and assign IDs
  const subscriptioncategories = eventData.subscriptioncategories.map((subCat: SubscriptionCategory) => {
    if (!subCat.hidden) {
      if (!subCat.title || subCat.title.trim() === '') {
        throw "Existe uma categoria de inscrição sem título!"
      } 

      if (!subCat.isfree && (subCat.prevalue <= 0 || subCat.posvalue <= 0)) {
        throw `A categoria de inscrição ${subCat.title} tem valores inválidos!`
      }
    
      if (subCat.limited_stock && (!subCat.quantity || subCat.quantity <= 0)) {
        throw `A categoria de inscrição ${subCat.title} tem estoque limitado mas quantidade inválida!`
      }
    }

    return {
      id: subCat.id.includes('newsubc-') ? 0 : getNewId(subCat.id, subscriptionCategoryIdMap),
      posvalue: subCat.posvalue,
      prevalue: subCat.prevalue,
      title: subCat.title,
      quantity: subCat.quantity,
      isfree: subCat.isfree,
      hidden: subCat.hidden,
      limited_stock: subCat.limited_stock
    };
  });

  return {
    event: {
      id: 0,
      startdate: new Date(eventData.startdate).toISOString().replace(/\.\d{3}Z$/, ''),
      layout: eventData.layout,
      introduction: eventData.introduction,
      logourl: eventData.logourl,
      paymentdate: new Date(eventData.paymentdate).toISOString().replace(/\.\d{3}Z$/, ''),
      creationdate: new Date().toISOString().replace(/\.\d{3}Z$/, ''),
      administratorid: 1,
      nde: "",
      status: "Active",
      color1: eventData.color1,
      color2: eventData.color2,
      route: eventData.route
    },
    banner: {
      id: 0,
      title: eventData.banner.title,
      backgroundimgs: backgroundimgs
    },
    place: {
      id: 0,
      name: eventData.place.name,
      description: eventData.place.description,
      district: eventData.place.district,
      city: eventData.place.city,
      state: eventData.place.state,
      cep: eventData.place.cep,
      number: eventData.place.number,
      reference: eventData.place.reference,
      street: eventData.place.street,
      complement: eventData.place.complement,
      telephone: eventData.place.telephone,
      imgs: placeImgs,
      youtubeurl: eventData.place.youtubeurl
    },
    schedule: {
      days: scheduleDays
    },
    expositor: {
      id: 1, // Assigned ID to expositor
      manuallink: eventData.expositor.manuallink,
      expositors: eventData.expositor.expositors,
      name: eventData.expositor.name,
      telephones: eventData.expositor.telephones,
      email: eventData.expositor.email
    },
    sponsor_category: sponsorCategories,
    sponsors: sponsors,
    participants: participants,
    subscriptioncategories: subscriptioncategories,
    category: categories,
    functions: eventData.functions.map((func) => ({
      id: getNewId(func.id, functionIdMap),
      name: func.name
    })),
    assignments: allAssignments,
  };
}
