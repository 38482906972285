import React from "react";
import { ScheduleDay, ScheduleModule, SchedulePlace } from "../../../../../types/EventData";
import formatTime from "../../../../../utils/helpers/formatTime";
import addMinutesFromDate from "../../../../../utils/helpers/addMinutesFromDate";
import DraggableList from "../../DraggableList";
import truncateString from "../../../../../utils/helpers/truncateString";

interface ModuleListProps {
  modules: ScheduleModule[];
  scheduleDay: ScheduleDay;
  schedulePlace: SchedulePlace;
  selectedId?: string;
  handleModuleSelect: (id?: string) => void;
  handleModuleCreate: () => void;
  handleModuleUpdate: (newModules: ScheduleModule[]) => void;
  handleModuleDelete: (newModules: ScheduleModule[]) => void;
}

export default function ModuleList({
  modules,
  schedulePlace,
  scheduleDay,
  selectedId,
  handleModuleCreate,
  handleModuleSelect,
  handleModuleUpdate,
  handleModuleDelete
}: ModuleListProps) {
  const getModuleStartTime = (index: number): Date => {
    const module = modules[index];
    if (module.independent) {
      return module.startime;
    }
    
    // For non-independent modules, calculate based on previous modules
    const previousModules = modules.slice(0, index).filter(m => !m.independent);
    const accumulatedMinutes = previousModules.reduce(
      (total, mod) => total + mod.moduleprogrammings.reduce((acc, prog) => acc + prog.minutes, 0),
      0
    );
    return addMinutesFromDate(schedulePlace.date, accumulatedMinutes);
  };

  const getModuleEndTime = (index: number): Date => {
    const module = modules[index];
    if (module.independent) {
      return module.endtime;
    }
    
    const moduleDuration = module.moduleprogrammings.reduce(
      (total, prog) => total + prog.minutes,
      0
    );
    return addMinutesFromDate(getModuleStartTime(index), moduleDuration);
  };

  return (
    <DraggableList
      id="droppable-module"
      title="Módulos"
      emptyText="Adicione um novo módulo."
      className="h-28 2xl:h-36 overflow-y-scroll px-2"
      items={modules}
      selectedId={selectedId}
      onCreate={handleModuleCreate}
      onSelect={handleModuleSelect}
      onOrderChange={handleModuleUpdate}
      onDelete={(id: string) => handleModuleDelete(modules.filter(module => module.id !== id))}
      renderItem={(item: ScheduleModule, index: number) => (
        <p className="uppercase text-sm font-semibold flex gap-2">
          {truncateString(item.title, 15)}
          <span
            className={`${selectedId === item.id ? "text-gray-100" : "text-gray-500"}`}
            style={{
              fontSize: "0.7rem",
            }}
          >
            {`${formatTime(getModuleStartTime(index))} - ${formatTime(getModuleEndTime(index))}`}
          </span>
        </p>
      )}
    />
  );
}
