import React, { useEffect, useState } from 'react'
import LayoutContainer from '../components/LayoutContainer';
import LayoutsHeaderGate from '../components/Header/LayoutsHeaderGate';
import LayoutSection from '../components/LayoutSection';
import SectionHeader from '../components/SectionHeader';
import LayoutSectionGroup from '../components/LayoutSectionGroup';
import SponsorsContainer from '../components/SponsorsContainer';
import { useNavigate } from 'react-router-dom';
import convertDateToString from '../utils/helpers/convertDateToString';
import SubscriptionCategoriesTables from '../components/SubscriptionCategoriesTables';
import Loading from "./Loading";
import { EventData, Layout } from '../types/EventData';
import { fetchEventData } from '../utils/api';

type SubscriptionsProps = {
  eventSlug?: string;
  preview?: boolean;
  previewData?: EventData;
  previewLayout?: Layout;
  onEventPageRedirect?: (page: string) => void;
}

function Subscriptions({
  eventSlug,
  preview,
  previewData,
  previewLayout,
  onEventPageRedirect
}: SubscriptionsProps) {
  const [data, setData] = useState<EventData>();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const [isHoveringSubscription, setHoveringSubscription] = useState<boolean>(false);
  
  useEffect(() => {
    const fetchData = async() => {
      if (preview) {
        setData(previewData);
        setLoading(false);
        return;
      }

      if (eventSlug) {
        const fetchData = await fetchEventData(eventSlug);
        setData(fetchData);
        setLoading(false);
      }
    };

    if (!data) {
      fetchData();
    }
  }, [previewData, preview, eventSlug, data]);

  if (loading) {
    return (<Loading />);
  }

  const handlePaymentRedirect = () => {
    if (!preview) {
      navigate(`/${eventSlug}/subscriptions/payment`);
    }
  }

  const renderLayoutSection = (containerized?: boolean) => {
    return (
      <div>
        <SectionHeader thin>Inscrição</SectionHeader>
          <LayoutSectionGroup containerized={containerized}>
            <p className=''>
              As inscrições poderão ser feitas pelo site até o dia&nbsp;
              <span 
                className="font-bold"
                style={{
                  color: `rgb(${data?.color2!})`
                }}
              >
                {convertDateToString(data?.paymentdate!)}
              </span>
            </p>
          </LayoutSectionGroup>
          <div className='border-transparent border-b-neutral-300 border-2 my-3'></div>
          <LayoutSectionGroup containerized={containerized}>
            <p className='font-bold'>Formas de Pagamento</p>
            <p className='text-lg pt-2 pb-1'>A taxa inscrição deverá ser paga da seguinte forma:</p>
            <ul className='ms-8 flex flex-col gap-1 list-disc'>
              <li>Cartão de Crédio em parcela única</li>
              <li>Cartão de Débito</li>
              <li>PIX ou Boleto</li>
            </ul>
            <p className='pt-6'>Logo após clicar em "Inscreva-se", insira todos os dados requisitados e prossiga com o pagamento, assim que concluido, serão enviados ao seu e-mail todas as instruções para prosseguir e o QR Code para ser utilizado no dia do evento.</p>
            <p className='pt-2'>Em casos de dúvidas, entre em contato através do e-mail &lt;emaildefinido@peloadmin.com.br&gt; ou na nossa area de suporte <a className='underline text-blue-600' href={`/${eventSlug}/support`}>"Fale Conosco"</a></p>
          </LayoutSectionGroup>
          <div className='border-transparent border-b-neutral-300 border-2 my-3'></div>
          <LayoutSectionGroup containerized={containerized}>
            <p className='font-bold'>Categorias de Inscrição</p>
            <SubscriptionCategoriesTables 
              color1={data?.color1!}
              paymentdate={data?.paymentdate!}
              subscriptionCategories={data?.subscriptioncategories!}              
            />
          </LayoutSectionGroup>
      </div>
    );
  }

  const hoverSubscriptionButtonColor = data?.color1!.map(c => Math.max(0, Math.min(255, Math.round(c * 0.85))));

  return (
    <div className="Subscriptions layout">
      <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
        {data && (
          <LayoutsHeaderGate 
            preview={preview} 
            data={data}
            onPreviewRedirect={onEventPageRedirect}
            layout={previewLayout || data.layout}
          />
        )}
        {data?.layout === "layout1" ? (
          <LayoutSection>
            {renderLayoutSection()}
          </LayoutSection>
        ) : (
          renderLayoutSection(true)
        )}
        <button 
          onClick={handlePaymentRedirect} 
          className='cursor-pointer self-center text-white text-lg rounded-lg max-w-48 text-center px-4 py-2 my-8 mb-16 transition-all'
          onMouseEnter={() => setHoveringSubscription(true)}
          onMouseLeave={() => setHoveringSubscription(false)}
          style={{
            backgroundColor: `rgb(${isHoveringSubscription ? hoverSubscriptionButtonColor : data!.color1!})`
          }}
        >
          Inscreva-se
        </button>
        <LayoutSection>
          <SponsorsContainer 
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  )
}

export default Subscriptions;