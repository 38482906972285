import Header from "../..";
import { EventData } from "../../../../types/EventData";
import Navbar from "../../Navbar";

type Layout3Header = {
  logourl: string;
  color1: number[];
  layout: "layout1" | "layout2" | "layout3" | "layout4";
  preview?: boolean;
  onPreviewRedirect?: (page: string) => void;
}

function Layout3Header({
  logourl,
  color1,
  layout,
  preview,
  onPreviewRedirect
}: Layout3Header) {
  return (
    <div className="flex flex-col sm:flex-row justify-between py-6">
      <Header logo={logourl} />
      <Navbar 
        column 
        logo={logourl} 
        color1={color1} 
        layout={layout}
        preview={preview}
        onPreviewRedirect={onPreviewRedirect}
      />
    </div>
  );
}

export default Layout3Header;