import { useState, useEffect, useRef } from "react";
import { EventData, Layout } from "../types/EventData";
import axios from "axios";
import parseResponseTypes from "../utils/parser/parseResponseTypes";
import { useParams } from "react-router-dom";
import { fetchEventData } from "../utils/api";

export const useFetchDemoData = () => {
  const { eventSlug } = useParams();
  const dataRef = useRef<EventData | null>(null);
  const [data, setData] = useState<EventData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log(`Event slug: ${eventSlug}`);
    const fetchData = async () => {
      try {
        const demoData = await fetchEventData(eventSlug!);
        if (!dataRef.current) {
          dataRef.current = demoData!;
          setData(demoData!);
        }
      } catch (err) {
        console.log(err)
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    if (!dataRef.current) {
      console.log(dataRef.current)
      fetchData();
    }
  }, []);
  
  return { data, setData, loading, error };
}