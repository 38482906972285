import React, { useState } from "react";
import {ScheduleDay, ScheduleProgramming, SchedulePlace, Participant} from "../../types/EventData";
import PlaceContainer from "./PlaceContainer";

type DayContainerProps = {
  day: ScheduleDay
  color1: number[];
  onCurriculumClick: (participant: Participant) => void;
}

function DayContainer({day, color1, onCurriculumClick}: DayContainerProps) {
  const getPlaceDuration = (place: SchedulePlace): number => {
    return place.placemodules.reduce((totalMinutes, module) => {
      const moduleDuration = module.moduleprogrammings.reduce(
        (acc, programming) => acc + programming.minutes, 
        0
      );
      return totalMinutes + moduleDuration;
    }, 0);
  };

  return (
    <div>
      <div className="flex flex-col px-4 gap-2">
        {day.dayplaces.map((place, index) => {
          // const accumulatedMinutes = day.dayplaces
          //   .slice(0, index)
          //   .reduce((acc, prevPlace) => acc + getPlaceDuration(prevPlace), 0);

          // const placeStartTime = new Date(day.date.getTime() + accumulatedMinutes * 60000);

          return (
            <div key={place.id}>
              <PlaceContainer
                place={place}
                // endTime={placeStartTime}
                endTime={place.date ?? day.date}
                onCurriculumClick={onCurriculumClick}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DayContainer;
