function truncateString(str: string, num: number) {
  if (str == null) {
    return "";
  }

  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export default truncateString;