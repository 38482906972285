import React, { useEffect, useState } from "react";
import { EventData, SponsorCategory, Sponsor, Category } from "../../../types/EventData";
import CategoryList from "../FormInputs/CategoryList";
import SponsorCategoryList from "../FormInputs/SponsorCategoryList";
import EditCategoryForm from "../FormInputs/EditCategoryForm";
import SponsorsForm from "../FormInputs/SponsorsForm";
import { UploadButton } from "@bytescale/upload-widget-react";

interface SponsorsProps {
  eventData: EventData;
  handleFormChange: (data: {[key: string]: any}) => void;
  submitForm: () => void;
}

const options = {
  apiKey: "public_12a1z8V6fXbMfWWFEV3xj6yqzse5",
  maxFileCount: 1,
  showFinishButton: false,
  styles: {
    colors: {
      primary: "#FF6B00"
    },
  },
  locale: {
    "uploadFileBtn": "Enviar logo",
    "orDragDropFile": "...ou arraste uma imagem.",
    "cancelBtn": "cancelar",
    "cancelPreviewBtn": "Cancelar",
    "continueBtn": "Continuar",
    "removeBtn": "remover",
    "removeBtnClicked": "removido",
    "submitBtnLoading": "Porfavor espere...",
  },
};

function Sponsors({eventData, handleFormChange}: SponsorsProps) {
  const [selectedCategory, setSelectedCategory] = useState<SponsorCategory>();
  const [selectedSponsor, setSelectedSponsor] = useState<Sponsor>();

  const handleCategorySelect = (selectedId: string) => {
    setSelectedCategory(eventData.sponsors.find((category) => category.id == selectedId));
    setSelectedSponsor(undefined);
  }

  const handleCategoryUpdate = (categoryUpdate: SponsorCategory[]) => {
    handleFormChange({
      sponsors: categoryUpdate
    });
  }

  const handleCategoryChangeInput = (key: keyof Category, value: any, sponsorId: string) => {
    const categoryUpdate = [...eventData.sponsors];
    const category = categoryUpdate.find(category => category.id === sponsorId);
    if (category) {
      //@ts-ignore
      category[key] = value;
    }
    handleFormChange({
      sponsors: categoryUpdate
    });
  }
  
  const handleSponsorSelect = (sponsorId: string) => {
    setSelectedSponsor(selectedCategory?.participants.find((sponsor) => sponsor.id == sponsorId))
  }

  const handleSponsorUpdate = (sponsorUpdate: Sponsor[]) => {
    selectedCategory!.participants = sponsorUpdate;
    handleFormChange({
      sponsors: selectedCategory
    });
  }

  const handleSponsorChangeInput = (value: string) => {
    selectedSponsor!.name = value;

    handleFormChange({
      sponsors: selectedCategory
    });
  }

  const handleSponsorPicChange = (newPicUrl: string) => {
    selectedSponsor!.pic = newPicUrl;
    handleFormChange({
      sponsors: selectedCategory
    });
  }


  return (
    <div className="sponsors">
      <div className="px-36 py-16 flex flex-col items-center gap-8 xl:flex-row xl:items-start xl:gap-0">
        <div>
          <h2 className="text-3xl font-bold mb-4 text-center xl:text-start">Categorias</h2>
          <SponsorCategoryList
            sponsorCategories={eventData.sponsors}
            onDelete={handleCategoryUpdate}
            onCreate={handleCategoryUpdate}
            onSelect={handleCategorySelect}
            onDrag={handleCategoryUpdate}
          />
        </div>
        <div className="flex xl:max-w-[800px] px-12">
          {selectedCategory ? (
            <div>
              <EditCategoryForm 
                category={selectedCategory}
                onChange={handleCategoryChangeInput}
                sponsor
              />
              <h2 className="text-3xl font-bold mb-4 mt-6">Patrocinadores</h2>
              <SponsorsForm 
                sponsors={selectedCategory.participants}
                onSelect={handleSponsorSelect}
                onUpdate={handleSponsorUpdate}
              />
            </div>
          ) : (
            <div className="flex items-center h-full">
              <p className="text-lg text-gray-300 font-bold mb-4">Selecione uma categoria para editar</p>
            </div>
          )}
        </div>
        <div className="flex-grow">
          {selectedSponsor && (
            <div className="">
              <h2 className="text-3xl font-bold">Editar Patrocinador</h2>
              <div className="flex flex-col items-center xl:items-start gap-12 mt-8">
                <div>
                  <h2 className="text-xl font-bold">Nome</h2>
                  <input 
                    type="text" 
                    placeholder="DIGITE O NOME DO PATROCINADOR"
                    value={selectedSponsor.name}
                    onChange={(e) => handleSponsorChangeInput(e.target.value)}
                    className="w-full text-xs shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border max-w-80"
                  />
                </div>
                <div>
                  <div className="flex">
                    <h2 className="text-xl font-bold">Foto</h2>
                    <p className="text-xs text-gray-500 italic">Opcional</p>
                  </div>
                  <UploadButton
                    options={options}
                    // onComplete={(files) => onChange("profilepic", files.map(x => x.fileUrl).join("\n"), participant.id)}
                    onComplete={(files) => handleSponsorPicChange(files.map(x => x.fileUrl).join("\n"))}
                  >
                    {({onClick}) =>
                      <div>
                        <img 
                          // src={participant.profilepic}
                          src={selectedSponsor.pic}
                          className="rounded-lg my-2 cursor-pointer hover:brightness-75 transition-all transition-duration-300 border-2 border-black/10 p-2"
                          onClick={onClick}
                          style={{
                            width: '150px',
                            height: '150px',
                            objectFit: 'contain'
                          }}
                        />
                      </div>
                    }
                  </UploadButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sponsors;