import React, { useState } from 'react';
import ProfileBadge from '../../ProfileBadge';
import { Participant } from '../../../types/EventData';
import TextContainer from './TextContainer';

interface IntroductionData {
  title: string;
  content: string[];
  author?: Participant;
  authorLeft?: boolean;
  authorCenter?: boolean;
  [key: string]: any;
}

function IntroductionSection({ title, content, author, authorCenter, authorLeft, ...props }: IntroductionData) {
  const [introductionAuthor] = useState<Participant>(author || {
    id: "",
    cpf: "",
    profilepic: 'https://image-cdn-ak.spotifycdn.com/image/ab67706c0000da8463bcdace67f79859e30a17fa',
    name: "Autor Indefinido",
    description: "Indefinido",
    categories: []
  });

  return (
    <div className="introductionSection">
      {authorCenter ? (
        <div className="flex flex-col items-center justify-center px-12 gap-4">
          <ProfileBadge profile={introductionAuthor} />
          <TextContainer
            title={title}
            titleCenter
            content={content}
            authorName={introductionAuthor.name}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center px-12 py-28 gap-12">
          {authorLeft && <ProfileBadge profile={introductionAuthor} />}
          <TextContainer
            title={title}
            content={content}
            authorName={authorLeft ? introductionAuthor.name : ''}
          />
          {!authorLeft && <ProfileBadge profile={introductionAuthor} />}
        </div>
      )}
    </div>
  );
}

export default IntroductionSection;
