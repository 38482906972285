import { EventData } from "../../types/EventData";
import parseResponseTypes from "./parseResponseTypes";

export function parseEventData(eventData: EventData) {
  parseResponseTypes(eventData);

  // Parse categories
  eventData.categories.forEach((category) => {
    category.id = `newct-${category.id}`;
  });

  // Parse base participants 
  eventData.participants.forEach((participant) => {
    participant.id = `newpct-${participant.id}`;
    // if (participant.categoryid && participant.category) {
    //   participant.category.id = `newct-${participant.categoryid}`;
    //   participant.categoryid = `newct-${participant.categoryid}`;
    // }
    if (!participant.categories) {
      participant.categories = [];
    }
    participant.categories.forEach((category) => {
      category.id = `newct-${category.id}`;
    })
  });
  
  // Parse schedule structure
  eventData.schedule.days.forEach(day => {
    const numericDayId = day.id;
    day.id = `newday-${numericDayId}`;
    day.date = new Date(day.date);

    day.dayplaces.forEach(place => {
      const numericPlaceId = place.id;
      place.id = `newplace-${numericDayId}-${numericPlaceId}`;
      place.date = place.date ? new Date(place.date) : new Date();
      
      place.placemodules.forEach(module => {
        const numericModuleId = module.id;
        module.independent = module.independent || false;
        module.startime = module.startime ? new Date(module.startime) : new Date();
        module.endtime = module.endtime ? new Date(module.endtime) : new Date();
        module.id = `newmodule-${numericDayId}-${numericPlaceId}-${numericModuleId}`;
        
        if (module.assignments) {
          module.assignments.forEach(assignment => {
            assignment.id = `assignment-${assignment.id}`;
            assignment.participant.id = `newpct-${assignment.participant.id}`;
            assignment.function.id = `newfunction-${assignment.function.id}`;
            // if (assignment.participant.category) {
            //   assignment.participant.category.id = `newct-${assignment.participant.category.id}`;
            // }
            assignment.participant.categories.forEach((category) => {
              category.id = `newct-${category.id}`;
            })
          });
        }

        module.moduleprogrammings.forEach(programming => {
          const numericProgrammingId = programming.id;
          programming.id = `newprogramming-${numericDayId}-${numericPlaceId}-${numericModuleId}-${numericProgrammingId}`;
          
          if (programming.assignments) {
            programming.assignments.forEach(assignment => {
              assignment.id = `assignment-${assignment.id}`;
              assignment.participant.id = `newpct-${assignment.participant.id}`;
              assignment.function.id = `newfunction-${assignment.function.id}`;
              // if (assignment.participant.category) {
              //   assignment.participant.category.id = `newct-${assignment.participant.category.id}`;
              // }
              assignment.participant.categories.forEach((category) => {
                category.id = `newct-${category.id}`;
              })
            });
          }
        });
      });
    });
  });

  eventData.functions.forEach((functionData, functionIndex) => {
    functionData.id = `newfunction-${functionData.id}`;
  });

  // Parse sponsors
  eventData.sponsors.forEach((sponsor, sponsorIndex) => {
    sponsor.id = `sponsorctg-${sponsorIndex}`;
    sponsor.participants.forEach((participant) => {
      participant.id = `sponsor-${participant.id}`;
    });
  });

  // Parse subscription categories
  eventData.subscriptioncategories.forEach((subscription) => {
    subscription.id = `subc-${subscription.id}`;
    subscription.isfree = subscription.prevalue === 0 || subscription.posvalue === 0;
  });
}