import { motion } from "framer-motion";
import React from "react";

interface ModalProps extends React.HTMLAttributes<HTMLElement> {
  visible?: boolean;
  width?: number;
  height?: number;
  hideClose?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
}

export default function Modal({
  visible,
  width,
  height,
  hideClose,
  children,
  className,
  onClose
}: ModalProps) {
  return (
    <motion.div
      className={`modal top-0 start-0 fixed z-30 w-screen h-screen ${visible ? "flex" : "hidden"} justify-center items-center`}
      onClick={onClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: visible ? 1 : 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      <motion.div
        className={`bg-white z-10 shadow-lg rounded-lg ${className}`}
        style={{
          width: width ? `${width}px` : '400px',
          height: height ? `${height}px` : '500px'
        }}
        onClick={(e) => e.stopPropagation()}
        initial={{ scale: 0 }}
        animate={{ scale: visible ? 1 : 0 }}
        exit={{ scale: 0 }}
        transition={{
          type: "spring",
          stiffness: 500,
          damping: 25,
          duration: 0.8
        }}
      >
        {/* {!hideClose && (
          <button 
            className="absolute end-6 text-2xl font-bold drop-shadow-lg hover:scale-110 translate-y-1 hover:translate-y-0 cursor-pointer transition transition-all transition-duration-700"
            onClick={onClose}
          >
            x
          </button>
        )} */}
        {children}
      </motion.div>
      <div className="absolute w-full h-screen top-0 start-0 bg-black opacity-15" />
    </motion.div>
  );
}
