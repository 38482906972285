import { delay, motion } from "framer-motion";
import React from "react";

interface ProgressItemProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  selected?: boolean;
  delay?: number;
}

function ProgressItem({children, selected, delay}: ProgressItemProps) {
  return (
    <motion.li
      initial={{ opacity: 0, scale: 1.2 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ delay: delay ?? 0.1, duration: 0.2, ease: "easeOut" }}
      className={`border-b-4 ${selected ? 'border-gray-800' : 'border-gray-400'} pb-1 transition-colors transition-duration-300`}
    >
      {children}
    </motion.li>
  );
}

export default ProgressItem;