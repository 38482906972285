import axios from 'axios';
import parseResponseTypes from './parser/parseResponseTypes';
import { EventData } from '../types/EventData';
import { DraftData } from '../types/Drafts';
import { parseEventData } from './parser/parseEventData';

export const apiUrl = 'https://api.credentech.com.br';
// const apiUrl = 'https://devapi.credentech.com.br';
// const apiUrl = 'http://localhost:3333';
export const livePreviewSocketUrl = "https://livepreview.credentech.com.br"

export const fixDrafts = () => {
  return axios.post(`${apiUrl}/draft/fix`);
}

export const fetchAllDrafts = async (): Promise<EventData[]> => {
  try {
    const response = await axios.get(`${apiUrl}/draft/get_all`);
    const draftData = response.data;

    return draftData;
  } catch(e) {
    console.log(e);
    throw "Erro no fetch de todos drafts";
  }
}

export const fetchDraftData = async (draftId: string): Promise<EventData> => {
  try {
    const response = await axios.get(`${apiUrl}/draft/get_draft/${draftId}`);
    const data = response.data as EventData;

    // parseResponseTypes(data);

    data.startdate ??= new Date();
    data.paymentdate ??= new Date();
    data.creationdate ??= new Date();
    data.place.cep ??= '';
    data.place.city ??= '';
    data.place.complement ??= '';
    data.place.description ??= '';
    data.place.district ??= '';
    data.place.imgs ??= [];
    data.place.name ??= '';
    data.place.number ??= '';
    data.place.reference ??= '';
    data.place.state ??= '';
    data.place.street ??= '';
    data.place.telephone ??= '';
    data.place.youtubeurl ??= '';
    data.expositor.email ??= '';
    data.expositor.expositors ??= [];
    data.expositor.manuallink ??= '';
    data.expositor.name ??= '';
    data.expositor.telephones ??= [];

    parseEventData(data);
    
    return data;
  } catch(e) {
    console.log(e);
    throw "Erro no fetch do draftid: " + draftId;
  }
}

export const generateDraftId = async () : Promise<number> => {
  try {
    const response = await axios.post(`${apiUrl}/draft/generate`);
    console.log("chamou o generate")
    return response.data;
  } catch(e) {
    console.log(e);
    throw "Erro ao gerar um novo draft id";
  }
}

export const deleteDraftById = async (draftId: number) => {
  try {
    return await axios.delete(`${apiUrl}/draft/delete/${draftId}`);
  } catch(e) {
    console.log(e);
    throw "Erro ao deletar o evento de id " + draftId;
  }
}

export const updateDraftById = async (draftId: number, data: string)  => {
  try {
    const response = await axios.post(`${apiUrl}/draft/update/${draftId}`, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log("Draft " + draftId + " atualizado com sucesso!");
    console.log(data);
    return response;
  } catch(e) {
    console.log(e);
    throw "Erro ao atualizar draft " + draftId;
  }
}

export const fetchEventData = async (eventId: string) => {
  try {
    const response = await axios.get(`${apiUrl}/${eventId}`);
    const eventData = response.data;
    
    parseResponseTypes(eventData);

    eventData.paymentdate = eventData.paymentdate || new Date(2024, 8, 8);
    eventData.color1 = (!eventData.color1 || eventData.color1.length == 0) ? [37, 99, 235] : eventData.color1;
    eventData.color2 = (!eventData.color2 || eventData.color2.length == 0) ? [37, 99, 235] : eventData.color2;
    
    return eventData;
  } catch (error) {
    console.error(error);
    throw "Falha ao buscar os dados do evento.";
  }
}

export const fetchEventDataById = async(eventId: number) => {
  try {
    const response = await axios.get(`${apiUrl}/get_event/${eventId}`);
    const eventData = response.data as EventData;
    return eventData;
  } catch(error) {
    console.error(error);
    throw "Falha ao buscar os dados do evento.";
  }
}

export const fetchEventEditData = async(eventId: number) => {
  try {
    const response = await axios.get(`${apiUrl}/get_event/${eventId}`);
    const eventData = response.data as EventData;

    parseEventData(eventData);
    return eventData;
  } catch(error) {
    console.error(error);
    throw "Falha ao buscar os dados do evento.";
  }
}

export const fetchCreateEvent = async (eventData: string) => {
  try {
    const response = await axios.post(`${apiUrl}/create_event`, eventData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
    console.log('Evento criado com sucesso', response.data);
    return response;
  } catch (error) {
    console.log(error)
    throw "Erro ao criar o evento";
  }
}

export const fetchEditEvent = async (eventData: string, eventId: string) => {
  try {
    const response = await axios.put(`${apiUrl}/edit_event/${eventId}`, eventData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log('Evento editado com sucesso', response.data);
    return response;
  } catch (error) {
    console.log(error)
    throw "Erro ao editar o evento";
  }
}

export async function getEventSubscribers(eventId: string) {
  try {
    const response = await axios({
      method: 'get',
      url: `${apiUrl}/get_event_subscribers/${eventId}`,
      withCredentials: true
    });

    console.log(response)
    const parsedSubscribers: any[] = Object.assign(response.data);

    parsedSubscribers.forEach((subscriber) => {
      subscriber.birthdate = new Date(subscriber.birthdate);
      subscriber.dateofsubscription = new Date(subscriber.dateofsubscription);
    });

    return parsedSubscribers;
  } catch(err) {
    console.log("Failed while trying to get subscribers");
    console.log(err);
  }
}

export async function fetchLogin(username: string, password: string) {
  try {
    const loginForm = new URLSearchParams();

    loginForm.append("username", username);
    loginForm.append("password", password);

    const response = await axios({
      method: "post",
      url: `${apiUrl}/login`,
      data: loginForm,
      withCredentials: true
    });

    return response;
  } catch(err) {
    throw err;
  }
}

export async function deleteEvent(eventId: number) {
  try {
    const response = await axios.delete(`${apiUrl}/delete_event/${eventId}`, {
      withCredentials: true
    });

    return response;
  } catch(err) {
    console.log("Falha ao deletar o evento");
    console.log(err);
  }
}

export async function fetchEventsDashboard() {
  try {
    // const response = await axios({
    //   method: "get",
    //   url: `${apiUrl}/get_event_dashboard`,
    //   withCredentials: true
    // });
    const response = await axios.get(`${apiUrl}/get_event_dashboard`, {
      withCredentials: true
    });
    if (response.data === "No events found") {
      return [];
    }
    return response.data;
  } catch(err) {
    console.log("Falha ao tentar pegar a dashboard de eventos");
    console.log(err);
  }
}

export async function updateLivePreviewData(uuidRoom: string, data: EventData) {
  try {
    await axios.put(`${livePreviewSocketUrl}/livepreview/update`, {
      uuid: uuidRoom,
      json: JSON.stringify(data)
    });
  } catch(err) {
    console.log("Falha ao atualizar o live preview");
    console.log(err);
  }
}