import React, { useEffect, useState } from "react";

import EventBanner from "../../components/Introduction/EventBanner";
import IntroductionSection from "../../components/Introduction/IntroductionSection";
import LayoutSection from "../../components/LayoutSection";
import SpeakerCarousel from "../../components/Speakers/SpeakerCarousel";
import QuickLinks from "../../components/QuickLinks";
import SectionHeader from "../../components/SectionHeader";
import LocationDetails from "../../components/Location/LocationDetails";
import LocationAddress from "../../components/Location/LocationAddress";
import LocationMap from "../../components/Location/LocationMap";
import SponsorsContainer from "../../components/SponsorsContainer";
import LayoutContainer from "../../components/LayoutContainer";
import Navbar from "../../components/Header/Navbar";
import VideoPlayer from "../../utils/ui/VideoPlayer";
import useWindowSize from "../../hooks/useWindowSize";
import Loading from "../Loading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { EventData } from "../../types/EventData";
import { fetchEventData } from "../../utils/api";
import Header from "../../components/Header";

interface Layout2Props {
  eventSlug?: string;
  preview?: boolean;
  previewData?: EventData;
  onEventPageRedirect?: (page: string) => void;
}

function Layout2({
  eventSlug, 
  preview, 
  previewData,
  onEventPageRedirect
}: Layout2Props) {
  const {windowSize} = useWindowSize();
  const [data, setData] = useState<EventData>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async() => {
      if (preview) {
        setData(previewData);
        setLoading(false);
        return;
      }

      if (eventSlug) {
        const fetchData = await fetchEventData(eventSlug);
        setData(fetchData);
        setLoading(false);
      }
    };

    if (!data) {
      fetchData();
    }
  }, [previewData, preview, eventSlug, data]);

  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="Layout2 layout">
        <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
          <Header
            small
            logo={data?.logourl!}
            preview={preview}
            onClick={() => onEventPageRedirect && onEventPageRedirect("home")}
          />
          <Navbar 
            className="py-5" 
            color1={data?.color1!} 
            logo={data?.logourl!} 
            layout={data?.layout!} 
            preview={preview}
            onPreviewRedirect={onEventPageRedirect}
          />
          <LayoutSection noBorder>
            <EventBanner 
              color1={data?.color1!}
              color2={data?.color2!}
              banner={data?.banner!}
              eventDate={data?.startdate!}
              place={data?.place!}
            />
            <IntroductionSection
              title="Introdução"
              authorLeft
              content={data!.introduction}
              author={data?.participants.find((participant) => participant.introductionauthor)!}
            />
          </LayoutSection>
          <LayoutSection>
          <QuickLinks 
            horizontal 
            eventSlug={eventSlug}
            preview={preview}
            onPreviewRedirect={onEventPageRedirect}
          />
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Palestrantes</SectionHeader>
            <SpeakerCarousel
              color1={data?.color1!}
              authors={data?.participants.filter((participant) => participant.isspeaker)!}
            />
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Localização</SectionHeader>
            <div className="flex gap-4">
              <div>
                <LocationMap
                  width={windowSize.width < 900 ? 300 : 450 * (windowSize.width * 0.0008)}
                  height={windowSize.width < 900 ? 320 : 400}
                  className="flex justify-center mb-12 mt-4"
                  location={data?.place!} 
                />
                <LocationAddress
                  street={data?.place.street!}
                  number={data?.place.number!}
                  district={data?.place.district!}
                  city={data?.place.city!}
                  state={data?.place.state!}
                  telephone={data?.place.telephone!}
                />
              </div>
              <div
                style={{
                  maxWidth: (windowSize.width < 1200 ? '400px' : '550px'),
                  margin: 'auto'
                }}
              >
                <Swiper
                  modules={[Navigation, Pagination]}
                  pagination={{clickable: true}}
                  navigation={true}
                >
                  {/* <SwiperSlide>
                    <VideoPlayer 
                      url="https://www.youtube.com/watch?v=NpLuHEA4plA" 
                      width={windowSize.width < 1200 ? 300 : 400}
                      height={windowSize.width < 900 ? 320 : 400}
                    />
                  </SwiperSlide> */}
                  {/* <SwiperSlide>
                    <VideoPlayer 
                      url="https://www.youtube.com/watch?v=NpLuHEA4plA" 
                      width={windowSize.width < 1200 ? 300 : 400}
                      height={windowSize.width < 900 ? 320 : 400}
                    />
                  </SwiperSlide> */}
                  {data?.place.imgs?.map((placeImgSrc) => {
                    const width = windowSize.width < 1200 ? 300 : 400;
                    const height = windowSize.width < 900 ? 320 : 300;
                    return (
                      <SwiperSlide>
                        <img
                          className={`m-auto w-[${width}px] h-[${height}px] pb-4`}
                          width={width}
                          height={height}
                          src={placeImgSrc.src} 
                          alt={placeImgSrc.alt} 
                        />
                      </SwiperSlide>
                    )
                  })}
                  {data?.place.youtubeurl && (
                    <SwiperSlide>
                      <VideoPlayer 
                        url={data?.place.youtubeurl}
                        width={windowSize.width < 1200 ? 300 : 400}
                        height={windowSize.width < 900 ? 320 : 400}
                      />
                    </SwiperSlide>
                  )}
                </Swiper>
                <LocationDetails name={data?.place.name!} description={data?.place.description!}  />
              </div>
            </div>
          </LayoutSection>
          <LayoutSection>
            <SponsorsContainer 
              sponsorCategories={data?.sponsors!}
            />
          </LayoutSection>
        </LayoutContainer>
    </div>
  );
}

export default Layout2;