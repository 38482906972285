import React, { useEffect, useState } from "react";
import { SubscriptionCategory } from "../../../../types/EventData";
import { NumericFormat } from 'react-number-format';

interface EditSubscriptionCategoryFormProps {
  paymentdate: Date;
  subscriptioncategory: SubscriptionCategory;
  onChange: (key: keyof SubscriptionCategory, value: any, categoryId: string) => void;
}

export default function EditSubscriptionCategoryForm({paymentdate, subscriptioncategory, onChange}: EditSubscriptionCategoryFormProps) {
  const isFreeChange = (checked: boolean) => {
    if (checked) {
      onChange("prevalue", 0, subscriptioncategory.id);
      onChange("posvalue", 0, subscriptioncategory.id);
    }

    onChange("isfree", checked, subscriptioncategory.id);
  }

  return (
    <div className="editSubscriptionCategoryForm">
      <h2 className="text-3xl font-bold mb-4">Edição de Categoria de Inscrição</h2>
      <div className="flex flex-col gap-12">
        <div>
          <h2 className="text-xl font-bold">Título</h2>
          <input 
            type="text" 
            placeholder="DIGITE O TITULO DA CATGORIA"
            value={subscriptioncategory.title}
            onChange={(e) => onChange("title", e.target.value, subscriptioncategory.id)}
            className="w-full text-xs shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border max-w-80"
          />
        </div>
        <div>
          <div className="flex gap-4">
            <h2 className="text-xl font-bold">Estoque de vagas limitado</h2>
            <input 
              type="checkbox" 
              checked={subscriptioncategory.limited_stock}
              onChange={(e) => onChange("limited_stock", e.target.checked, subscriptioncategory.id)}
            />
          </div>
        </div>
        <div>
          <h2 className="text-xl font-bold">Quantidade de Vagas</h2>
          <input 
            type="number" 
            placeholder="DIGITE A QUANTIDADE DE VAGAS"
            value={subscriptioncategory.quantity}
            disabled={!subscriptioncategory.limited_stock}
            onChange={(e) => onChange("quantity", Math.max(0, parseInt(e.target.value)), subscriptioncategory.id)}
            min="0"
            className="w-full text-xs shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border max-w-80"
          />
        </div>
        <div className="flex gap-4">
          <h2 className="text-xl font-bold">Inscrição Gratuita</h2>
          <input 
            type="checkbox" 
            checked={subscriptioncategory.isfree}
            onChange={(e) => isFreeChange(e.target.checked)}
          />
        </div>
        {subscriptioncategory.isfree ? (
          <div>
            <h2 className="text-xl font-bold text-slate-300">Essa inscrição é gratuita, os valores serão definidos como R$ 0.00</h2>
          </div>
        ) : (
          <div className="flex gap-8">
            <div>
              <div className="flex gap-2">
                <h2 className="text-xl font-bold">Pré Valor</h2>
                <p className="text-xs text-gray-500 italic">Antes de {paymentdate.toLocaleDateString('pt-BR')}</p>
              </div>
              <NumericFormat 
                value={subscriptioncategory.prevalue}
                prefix="R$"
                onValueChange={(values) => {
                  onChange("prevalue", values.floatValue, subscriptioncategory.id)
                }}
                className="w-full text-xs shadow-lg rounded-full mt-2 px-4 font-semibold py-2 border max-w-80"
              />
            </div>
            <div>
              <div className="flex gap-2">
                <h2 className="text-xl font-bold">Pós Valor</h2>
                <p className="text-xs text-gray-500 italic">Após {paymentdate.toLocaleDateString('pt-BR')}</p>
              </div>
              <NumericFormat 
                value={subscriptioncategory.posvalue}
                prefix="R$"
                onValueChange={(values) => {
                  onChange("posvalue", values.floatValue, subscriptioncategory.id)
                }}
                className="w-full text-xs shadow-lg rounded-full mt-2 px-4 font-semibold py-2 border max-w-80"
              />
            </div>
          </div>
        )}

      </div>
    </div>
  )
}