import React, { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip'
import { CiImageOn } from 'react-icons/ci';
import { RiPencilFill } from "react-icons/ri";
import { FaExternalLinkSquareAlt, FaTrashAlt } from "react-icons/fa";
import { ClipLoader } from "react-spinners";

import TableHead from "./TableHead";
import TableCell from "./TableCell";
import axios from "axios";
import { FaMoneyBillWave } from "react-icons/fa6";
import { deleteEvent, fetchAllDrafts, fetchEventsDashboard } from "../../utils/api";
import convertDateToString from "../../utils/helpers/convertDateToString";
import Modal from "react-responsive-modal";
import { motion } from "framer-motion";
import truncateString from "../../utils/helpers/truncateString";
import ConfirmActionModal from "../../utils/ui/ConfirmActionModal";
import { useNavigate } from "react-router-dom";

type EventItem = {
  eventid: number;
  logourl: string;
  nde: string;
  eventtitle: string;
  creationdate: string;
  startdate: string;
  administrator: string;
  status: string;
  route: string;
}

interface EventsDashboardTableProps {
  onLoad?: () => void;
}

export default function EventsDashboardTable({
  onLoad
}: EventsDashboardTableProps) {
  const [events, setEvents] = useState<EventItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [toDeleteEventId, setToDeleteEventId] = useState<number | null>(null);
  const [toRedirectEventId, setToRedirectEventId] = useState<number | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchEventsDashboard().then((events) => {
      console.log(events)
      setEvents(events);
      onLoad && onLoad();
    });
  }, []);

  const handleDelete = async (eventId: number | null) => {
    if (!eventId) {
      return;
    }

    setLoading(true);
    try {
      await deleteEvent(eventId);
      setEvents(prevEvent => prevEvent.filter(event => event.eventid !== eventId));
      setToDeleteEventId(null)
      setLoading(false);
    } catch (error) {
      console.error('Erro ao deletar o evento:', error);
      setLoading(false);
    }
  }

  const handleEditRedirect = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, eventId: number | null) => {
    if (!eventId) {
      return;
    }
    const allDrafts = await fetchAllDrafts();
    
    if (allDrafts.find((e) => e.eventid == eventId)) {
      e.preventDefault();
      setToRedirectEventId(eventId);
      return;
    }
    
    navigate(`/events/edit/${eventId}`)
  }

  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      transition={{duration: 0.5}}
      className="eventsDashboardTable"
    >
      <table className="table-auto min-w-full leading-normal">
        <thead>
          <tr className="text-center">
            <TableHead>Logo</TableHead>
            <TableHead>Nome do Evento</TableHead>
            <TableHead>Data de Criação</TableHead>
            <TableHead>Data do Evento</TableHead>
            <TableHead>Ação</TableHead>
            <TableHead>Rota</TableHead>
            <TableHead>Gestor</TableHead>
            <TableHead>Status</TableHead>
          </tr>
        </thead>
        <tbody>
          {events.length == 0 && (
            <tr>
              <td 
                colSpan={9} 
                className="text-center py-5 text-stone-200 font-semibold"
              >
                Nenhum evento cadastrado.
              </td>
            </tr>
          )}
          {events.map((event, index) => (
            <tr className="bg-gray-50 hover:bg-gray-100 transition-all">
              <TableCell>
                <img src={event.logourl} style={{height: '50px'}} />
              </TableCell>
              <TableCell>{truncateString(event.eventtitle, 35)}</TableCell>
              <TableCell>{convertDateToString(new Date(event.creationdate))}</TableCell>
              <TableCell>{convertDateToString(new Date(event.startdate))}</TableCell>
              <TableCell>
                <a 
                  // href={`/events/edit/${event.eventid}`}
                  className="inline-block me-2 transition-transform duration-200 ease-in-out hover:scale-110 outline-none cursor-pointer"
                  data-tooltip-id={`evds-edit-tooltip-${index}`}
                  data-tooltip-content="Editar"
                  data-tooltip-place="bottom"
                  onClick={(e) => handleEditRedirect(e, event.eventid)}
                >
                  <RiPencilFill style={{height: '30px', width: '30px'}} />
                  <Tooltip id={`evds-edit-tooltip-${index}`} />
                </a>
                <a
                  // onClick={() => handleDelete(event.eventid)}
                  onClick={() => setToDeleteEventId(event.eventid)}
                  className="inline-block me-2 transition-transform duration-200 ease-in-out hover:scale-110 outline-none cursor-pointer"
                  data-tooltip-id={`evds-delete-tooltip-${index}`}
                  data-tooltip-content="Deletar"
                  data-tooltip-place="bottom"
                >
                  <FaTrashAlt style={{height: '30px', width: '30px'}} />
                  <Tooltip id={`evds-delete-tooltip-${index}`} />
                </a>
                <a 
                  href={`/events/subscribers/${event.eventid}`}
                  className="inline-block me-2 transition-transform duration-200 ease-in-out hover:scale-110 outline-none"
                  data-tooltip-id={`evds-subscriptions-tooltip-${index}`}
                  data-tooltip-content="Inscrições"
                  data-tooltip-place="bottom"
                >
                  <FaMoneyBillWave style={{height: '30px', width: '30px'}} />
                  <Tooltip id={`evds-subscriptions-tooltip-${index}`} />
                </a>
                <a 
                  href={`/${event.route}`}
                  target="_blank"
                  className="inline-block transition-transform duration-200 ease-in-out hover:scale-110 outline-none"
                  data-tooltip-id={`evds-visualize-tooltip-${index}`}
                  data-tooltip-content="Visualizar"
                  data-tooltip-place="bottom"
                >
                  <FaExternalLinkSquareAlt style={{height: '28px', width: '28px'}} />
                  <Tooltip id={`evds-visualize-tooltip-${index}`} />
                </a>
              </TableCell>
              <TableCell>/{event.route}</TableCell>
              <TableCell>{event.administrator}</TableCell>
              <TableCell>
                <div className="text-sm text-green-800 font-bold uppercase bg-green-400 px-4 py-1 rounded-full">
                  {event.status}
                </div>
              </TableCell>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal open={loading} onClose={() => setLoading(false)} center>
        <div className="p-24">
          <ClipLoader size={50} color={"#3b82f6"} loading={true} />
        </div>
      </Modal>
      <ConfirmActionModal
        visible={toDeleteEventId != null}
        question="Você têm certeza que deseja apagar o evento ? (Essa alteração não poderá ser desfeita!)"
        onCancel={() => setToDeleteEventId(null)}
        onConfirm={() => handleDelete(toDeleteEventId)}
      />
      <ConfirmActionModal
        visible={toRedirectEventId != null}
        question="Um rascunho desse evento já foi criado, tem certeza que deseja criar outro ?"
        onCancel={() => setToRedirectEventId(null)}
        onConfirm={() => navigate(`/events/edit/${toRedirectEventId}`)}
      />
    </motion.div>
  );
}