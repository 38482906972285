import React from "react";
import { Participant, SchedulePlace, ScheduleProgramming } from "../../../types/EventData";
import ModuleContainer from "../ModuleContainer";

interface PlaceContainerProps {
  place: SchedulePlace;
  endTime: Date;
  onCurriculumClick: (participant: Participant) => void;
}

export default function PlaceContainer({
  place,
  endTime,
  onCurriculumClick,
}: PlaceContainerProps) {
  const formatDate = (date: Date) => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const minutesReducer = (accumulator: number, reducer: ScheduleProgramming) => accumulator + reducer.minutes;
  
  // Calculate total duration only from non-independent modules
  const totalModuleMinutes = place.placemodules
    .filter(module => !module.independent)
    .reduce((acc, re) => acc + re.moduleprogrammings.reduce((mAcc, mRe) => mAcc + mRe.minutes, 0), 0);

  // Find the latest end time considering both independent and non-independent modules
  const placeEndTime = place.placemodules.reduce((latestTime, module) => {
    if (module.independent && module.endtime) {
      return module.endtime > latestTime ? module.endtime : latestTime;
    }
    const moduleEndTime = new Date(endTime.getTime() + totalModuleMinutes * 60000);
    return moduleEndTime > latestTime ? moduleEndTime : latestTime;
  }, new Date(endTime.getTime() + totalModuleMinutes * 60000));

  return (
    <div>
      <p className="bg-black text-white px-2 py-1 rounded-md font-semibold">
        • {place.title}
        <span className="ms-12">{formatDate(endTime)} - {formatDate(placeEndTime)}</span>
      </p>
      <div className="flex flex-col gap-6">
        {place.placemodules.map((module, index) => {
          if (module.independent) {
            return (
              <ModuleContainer
                key={module.id}
                module={module}
                startDate={module.startime!}
                endDate={module.endtime!}
                onCurriculumClick={onCurriculumClick}
              />
            );
          }

          const accumulatedMinutes = place.placemodules
            .slice(0, index)
            .filter(m => !m.independent)
            .reduce((accumulator, reducer) => 
              accumulator + reducer.moduleprogrammings.reduce(minutesReducer, 0), 0);
          
          const moduleDuration = module.moduleprogrammings.reduce(minutesReducer, 0);
          
          const startDate = new Date(endTime.getTime() + accumulatedMinutes * 60000);
          const endDate = new Date(startDate.getTime() + moduleDuration * 60000);

          return (
            <ModuleContainer
              key={module.id}
              module={module}
              startDate={startDate}
              endDate={endDate}
              onCurriculumClick={onCurriculumClick}
            />
          );
        })}
      </div>
    </div>
  );
}