import React, { useEffect, useState } from "react";
import Colorful from '@uiw/react-color-colorful';
import { hsvaToHex, hsvaToRgba } from '@uiw/color-convert';
import { HexColorPicker } from "react-colorful";
import rgbToHsv from "../../../../utils/helpers/rgbToHsv";
import { rgbToHex } from "../../../../utils/helpers/rgbToHex";
import { hexToRgb } from "../../../../utils/helpers/hexToRgb";

type ColorSelectorProps = {
  title: string;
  rgbColor: number[];
  handleFormChange: (rgb: number[]) => void;
}

function ColorSelector({ handleFormChange, title, rgbColor }: ColorSelectorProps) {
  const [hex, setHex] = useState<string>("#fff");

  useEffect(() => {
    setHex(rgbToHex(rgbColor));
  }, [rgbColor]);

  return (
    <div className="ColorSelector">
      <h2 className="text-xl font-semibold">{title}</h2>
      {/* <Colorful
        key={JSON.stringify(hsva)}
        color={hsva}
        disableAlpha={true}
        className="w-32"
        onChange={(color) => {
          const { hsva } = color;

          setHsva(hsva);
          const rgba = hsvaToRgba({
            h: hsva.h,
            s: hsva.s,
            v: hsva.v,
            a: 1,
          });
          handleFormChange([rgba.r, rgba.g, rgba.b]);
        }}
      /> */}
      <HexColorPicker
        color={hex} 
        onChange={(hex) => {
          setHex(hex);
          handleFormChange(hexToRgb(hex));
        }} 
      />
      <div
        className="rounded-full m-auto mt-2"
        style={{
          width: "30px",
          height: "30px",
          background: hex,
        }}
      />
    </div>
  );
}

export default ColorSelector;
