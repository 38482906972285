import React, { useEffect, useState } from "react";
import LayoutContainer from "../components/LayoutContainer";
import LayoutsHeaderGate from "../components/Header/LayoutsHeaderGate";
import LayoutSection from "../components/LayoutSection";
import SponsorsContainer from "../components/SponsorsContainer";
import SectionHeader from "../components/SectionHeader";
import MultistepCheckout from "../components/MultistepCheckout";
import Loading from "./Loading";
import { EventData, Layout } from "../types/EventData";
import { fetchEventData } from "../utils/api";

type SubscriptionPaymentProps = {
  eventSlug?: string;
  preview?: boolean;
  previewData?: EventData;
  previewLayout?: Layout;
  onEventPageRedirect?: (page: string) => void;
}

function SubscriptionPayment({
  eventSlug,
  preview,
  previewData,
  previewLayout,
  onEventPageRedirect
}: SubscriptionPaymentProps) {
  const [data, setData] = useState<EventData>();
  const [loading, setLoading] = useState<boolean>(true);
  
  useEffect(() => {
    const fetchData = async() => {
      if (preview) {
        setData(previewData);
        setLoading(false);
        return;
      }

      if (eventSlug) {
        const fetchData = await fetchEventData(eventSlug);
        setData(fetchData);
        setLoading(false);
      }
    };

    if (!data) {
      fetchData();
    }
  }, [previewData, preview, eventSlug, data]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="subscriptionPayment">
      <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
        {data && (
          <LayoutsHeaderGate 
            preview={preview}
            data={data}
            onPreviewRedirect={onEventPageRedirect}
            layout={previewLayout || data.layout}
          />
        )}
        <LayoutSection transparent noBorder>
          <SectionHeader thin>Pagamento</SectionHeader>
          <MultistepCheckout 
            subscriptionCategories={data?.subscriptioncategories!}
            color1={data?.color1}
            color2={data?.color2}
            eventid={data?.eventid!}
          />
        </LayoutSection>
        <LayoutSection>
          <SponsorsContainer
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  );
}

export default SubscriptionPayment;