import React from "react";
import { ScheduleDay, ScheduleModule, SchedulePlace, ScheduleProgramming } from "../../../../../types/EventData";
import formatTime from "../../../../../utils/helpers/formatTime";
import addMinutesFromDate from "../../../../../utils/helpers/addMinutesFromDate";
import DraggableList from "../../DraggableList";
import truncateString from "../../../../../utils/helpers/truncateString";

interface ProgrammingListProps {
  programming: ScheduleProgramming[];
  scheduleDay: ScheduleDay;
  schedulePlace: SchedulePlace;
  modules: ScheduleModule[];
  moduleIndex: number;
  selectedId?: string;
  handleProgrammingSelect: (id?: string) => void;
  handleProgrammingCreate: () => void;
  handleProgrammingUpdate: (newProgramming: ScheduleProgramming[]) => void;
  handleProgrammingDelete: (newProgramming: ScheduleProgramming[]) => void;
}

export default function ProgrammingList({
  programming,
  scheduleDay,
  schedulePlace,
  modules,
  moduleIndex,
  selectedId,
  handleProgrammingCreate,
  handleProgrammingSelect,
  handleProgrammingUpdate,
  handleProgrammingDelete
}: ProgrammingListProps) {
  const getModuleStartTime = (): Date => {
    const currentModule = modules[moduleIndex];
    if (currentModule.independent) {
      return currentModule.startime;
    }

    const previousModules = modules.slice(0, moduleIndex).filter(m => !m.independent);
    const accumulatedMinutes = previousModules.reduce(
      (total, mod) => total + mod.moduleprogrammings.reduce((acc, prog) => acc + prog.minutes, 0),
      0
    );
    return addMinutesFromDate(schedulePlace.date, accumulatedMinutes);
  };

  const getProgrammingStartTime = (index: number): Date =>
    addMinutesFromDate(getModuleStartTime(), programming.slice(0, index).reduce((totalMinutes, prog) => totalMinutes + prog.minutes, 0));

  const getProgrammingEndTime = (index: number): Date =>
    addMinutesFromDate(getProgrammingStartTime(index), programming[index].minutes);

  return (
    <DraggableList
      id="droppable-programming"
      title="Programação"
      emptyText="Adicione uma nova programação"
      className="h-96 overflow-y-scroll px-1"
      items={programming}
      selectedId={selectedId}
      onSelect={handleProgrammingSelect}
      onCreate={handleProgrammingCreate}
      onOrderChange={handleProgrammingUpdate}
      onDelete={(id: string) => handleProgrammingDelete(programming.filter(prog => prog.id !== id))}
      renderItem={(item: ScheduleProgramming, index: number) => (
        <p className="uppercase text-sm font-semibold flex gap-2">
          {truncateString(item.theme, 15)}
          <span
            className={`${selectedId === item.id ? "text-gray-100" : "text-gray-500"}`}
            style={{
              fontSize: "0.7rem",
            }}
          >
            {item.minutes
              ? `${formatTime(getProgrammingStartTime(index))} - ${formatTime(getProgrammingEndTime(index))}`
              : "--:-- - --:--"}
          </span>
        </p>
      )}
    />
  );
}
