import { Participant, Category } from "../../types/EventData";

function filterParticipantsByCategory(participants: Participant[], category: Category): Participant[] {
  // return participants.filter((participant: Participant) => participant.category?.name === category.name);
  // console.log("categoria")
  // console.log(category);
  console.log(participants)
  return participants.filter((participant: Participant) => participant.categories.find((c) => c.id == category.id));
}

export default filterParticipantsByCategory;