import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout1 from "./layouts/Layout1";
import Layout2 from "./layouts/Layout2";
import Layout3 from "./layouts/Layout3";
import Layout4 from "./layouts/Layout4";
import { EventData, Layout } from "../types/EventData";
import { fetchEventData, livePreviewSocketUrl } from "../utils/api";
import Support from "./Support";
import Subscriptions from "./Subscriptions";
import Guests from "./Guests";
import Expositors from "./Expositors";
import Comissions from "./Comissions";
import Schedule from "./Schedule";
import { io, Socket } from "socket.io-client";
import parseResponseTypes from "../utils/parser/parseResponseTypes";

type LayoutButtonProps = {
  children: React.ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

const LayoutButton = ({
  children,
  selected,
  onClick
}: LayoutButtonProps) => {
  return (
    <button 
      className={`w-full flex items-center justify-center gap-2 ${selected ? "bg-orange-700 hover:bg-orange-800" : "bg-orange-500 hover:bg-orange-600"}  text-white px-4 py-2 rounded-lg transition transition-duration-300`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

const pagesComponentMap: Record<string, React.ComponentType<any>> = {
  support: Support,
  subscriptions: Subscriptions,
  schedule: Schedule,
  guests: Guests,
  expositors: Expositors,
  comissions: Comissions
}

const layoutsComponents: React.ComponentType<any>[] = [
  Layout1,
  Layout2,
  Layout3,
  Layout4
];

export default function Live() {
  const [expiredLive, setExpiredLive] = useState<boolean>(false);
  const [selectedLayout, setSelectedLayout] = useState<number>();
  const [previewData, setPreviewData] = useState<EventData>();
  const [eventPage, setEventPage] = useState<string>();
  const [forceLayoutRender, setForceLayoutRender] = useState(0);

  const {liveId} = useParams();
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    console.log("Procurando live de id");
    console.log(liveId);

    let socket = socketRef.current;

    if (!socket) {
      socket = io(livePreviewSocketUrl, {});
      socketRef.current = socket;
    }

    socket.on("expired_link", () => {
      setExpiredLive(true);
    });

    socket.on("livepreview_update", (data: any) => {
      if (expiredLive) {
        return;
      }

      setForceLayoutRender(prev => prev + 1);

      data = JSON.parse(data);
      parseResponseTypes(data);
      console.log(data)
      setPreviewData(data);
    });

    socket.emit("join_room", {
      room: liveId
    });

    // (async () => {
    //   const placeholderData: EventData = await fetchEventData("criptomoedas");
    //   setPreviewData(placeholderData);
    // })();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    }
  }, [liveId]);

  useEffect(() => {
    setEventPage("home");
  }, [selectedLayout]);

  const handleSelectLayout = (layoutNumber: number) => {
    if (selectedLayout == layoutNumber) {
      return setSelectedLayout(undefined);
    }
    setSelectedLayout(layoutNumber);
  }

  const handleEventPageRedirect = (page: string) => {
    setEventPage(page);
  }

  const RenderLayouts = () => {
    if (!selectedLayout) {
      return (
        <p className="text-center text-2xl text-black/20 font-bold">
          Selecione um layout para visualizar
        </p>
      );
    }

    if (eventPage == "home") {
      const LayoutComponent = layoutsComponents[selectedLayout - 1];
      return (
        <LayoutComponent
          key={`home${selectedLayout}-${forceLayoutRender}`}
          preview 
          previewData={previewData}
          onEventPageRedirect={handleEventPageRedirect}
        />
      );
    } else if (eventPage) {
      const PageComponent = pagesComponentMap[eventPage];
      if (!PageComponent) {
        console.log(eventPage);
        return <>undefined page</>
      }
      return (
        <PageComponent
          key={`${eventPage}-${forceLayoutRender}`}
          preview
          previewData={previewData}
          onEventPageRedirect={handleEventPageRedirect}
          previewLayout={`layout${selectedLayout}` as Layout}
        />
      );
    }
  }

  return (
    <div className="live">
      <AnimatePresence>
        {expiredLive ?  (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 1, ease: "easeOut"}}
            className="flex items-center justify-center w-screen h-screen"
          >
            <h2 className="font-black text-6xl text-black/20 tracking-tight">Sessão de Live Preview Expirada</h2>
          </motion.div>
        ) : (
          <>
            <motion.header
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{duration: 1, ease: "easeOut"}}
              className="text-center py-4 flex flex-col items-center"
            >
              <div>
                <img
                  width={60}
                  src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/credentechlogo.png" 
                  alt="Credentech" 
                />
                <h2 className="font-bold text-4xl">Event Live Viwer</h2>
              </div>
              <ul className="w-full px-32 flex lg:flex-row flex-col gap-1 lg:gap-6 mt-4">
                <li className="w-full">
                  <LayoutButton 
                    onClick={() => handleSelectLayout(1)} 
                    selected={selectedLayout == 1}
                  >
                    Layout 1
                  </LayoutButton>
                </li>
                <li className="w-full">
                  <LayoutButton 
                    onClick={() => handleSelectLayout(2)}
                    selected={selectedLayout == 2}
                  >
                    Layout 2
                  </LayoutButton>
                </li>
                <li className="w-full">
                  <LayoutButton 
                    onClick={() => handleSelectLayout(3)}
                    selected={selectedLayout == 3}
                  >
                    Layout 3
                  </LayoutButton>
                </li>
                <li className="w-full">
                  <LayoutButton 
                    onClick={() => handleSelectLayout(4)}
                    selected={selectedLayout == 4}
                  >
                    Layout 4
                  </LayoutButton>
                </li>
              </ul>
            </motion.header>
            <main>
              {RenderLayouts()}
            </main>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}