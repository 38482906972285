import React, { useEffect, useState } from 'react'
import LayoutContainer from '../components/LayoutContainer';
import LayoutSection from '../components/LayoutSection';
import SectionHeader from '../components/SectionHeader';
import CategorySection from '../components/CategorySection';
import SponsorsContainer from '../components/SponsorsContainer';
import LayoutsHeaderGate from '../components/Header/LayoutsHeaderGate';
import filterParticipantsByCategory from '../utils/helpers/filterParticipantsByCategory';
import Loading from "./Loading";
import { EventData, Layout } from '../types/EventData';
import { fetchEventData } from '../utils/api';

type GuestsProps = {
  eventSlug?: string;
  preview?: boolean;
  previewData?: EventData;
  previewLayout?: Layout;
  onEventPageRedirect?: (page: string) => void;
}

function Guests({
  eventSlug,
  preview,
  previewData,
  previewLayout,
  onEventPageRedirect
}: GuestsProps) {
  const [data, setData] = useState<EventData>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async() => {
      if (preview) {
        setData(previewData);
        setLoading(false);
        return;
      }

      if (eventSlug) {
        const fetchData = await fetchEventData(eventSlug);
        setData(fetchData);
        setLoading(false);
      }
    };

    if (!data) {
      fetchData();
    }
  }, [previewData, preview, eventSlug, data]);

  if (loading) {
    return (<Loading />);
  }

  // const guestsCategory = data?.categories.find((guest) => guest.guestcategory)
  const guestsCategories = data?.categories.filter((category) => category.guestcategory);

  const renderCategorySection = () => {
    return (
      <div>
        <SectionHeader>CONVIDADOS</SectionHeader>
        {/* <CategorySection
          category={guestsCategory!}
          participants={filterParticipantsByCategory(Object.values(data!.participants), guestsCategory!)}
        /> */}
        {guestsCategories?.map((category) => (
          <CategorySection
            category={category}
            participants={filterParticipantsByCategory(Object.values(data!.participants), category)}
          />
        ))}
      </div>
    )
  }

  return (
    <div className="Guests layout">
      <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
        {data && (
          <LayoutsHeaderGate
            preview={preview}
            data={data}
            onPreviewRedirect={onEventPageRedirect}
            layout={previewLayout || data.layout}
          />
        )}
        {/* <LayoutSection> */}
        {data?.layout == "layout2" ? (
          renderCategorySection()
        ) : (
          <LayoutSection>
            {renderCategorySection()}
          </LayoutSection>
        )}
        {/* </LayoutSection> */}
        <LayoutSection>
          <SponsorsContainer 
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  )
}

export default Guests;