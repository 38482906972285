import React, { useState } from "react";
import { FaTrash } from "react-icons/fa6";
import { Tooltip } from 'react-tooltip'
import { UploadButton } from "@bytescale/upload-widget-react";
import CreateListItemButton from "../FormInputs/CreateListItemButton";
import { EventData, Participant } from "../../../types/EventData";
import ParticipantsList from "../FormInputs/ParticipantsList";
import EditParticipantForm from "../FormInputs/EditParticipantForm";

type ParticipantsManagerProps = {
  eventData: EventData;
  handleFormChange: (data: {[key: string]: any}) => void;
  submitForm: () => void;
}

function ParticipantsManager({eventData, handleFormChange}: ParticipantsManagerProps) {
  const [selectedParticipant, setSelectedParticipant] = useState<Participant>();

  const handleParticipantSelect = (selectedId: string) => {
    setSelectedParticipant(eventData.participants.find((participant) => participant.id == selectedId));
  }

  const handleParticipantUpdate = (participantUpdate: Participant[]) => {
    handleFormChange({
      participants: participantUpdate
    });
  }

  const handleParticipantChangeInput = (key: keyof Participant, value: any, participantId: string) => {
    const participantUpdate = [...eventData.participants];
    const participant = participantUpdate.find(participant => participant.id === participantId);
    
    if (!participant) {
      return;
    }

    //@ts-ignore
    participant[key] = value;

    if (participant.introductionauthor) {
      participantUpdate.forEach(participant => {
        if (participant.id != participantId) {
          participant.introductionauthor = false;
        }
      })
    }

    handleFormChange({
      participants: participantUpdate
    });
  }

  return (
    <div className="participantsManager">
      <div className="px-36 py-16 flex flex-col items-center xl:items-start xl:flex-row">
        <div>
          <h2 className="text-3xl font-bold mb-4">Lista de Participantes</h2>
          <ParticipantsList
            participants={eventData.participants}
            onDelete={handleParticipantUpdate}
            onCreate={handleParticipantUpdate}
            onDrag={handleParticipantUpdate}
            onSelect={handleParticipantSelect}
          />
        </div>
        <div className="flex-grow max-w-[800px] px-12">
          {selectedParticipant ? (
            <EditParticipantForm 
              participant={selectedParticipant}
              categories={eventData.categories}
              onChange={handleParticipantChangeInput}
            />
          ) : (
            <div className="flex items-center h-full">
              <p className="text-lg text-gray-300 font-bold mb-4 mt-4">Selecione um participante para editar</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ParticipantsManager;