import React, { useState } from "react";
import { EventData, SubscriptionCategory } from "../../../types/EventData";
import Success from "../../../utils/ui/Success";
import SubscriptionsCategoryList from "../FormInputs/SubscriptionsCategoryList";
import EditSubscriptionCategoryForm from "../FormInputs/EditSubscriptionCategoryForm";

interface SubscriptionsProps {
  eventData: EventData;
  handleFormChange: (data: {[key: string]: any}) => void;
  submitForm: () => void;
}

function Subscriptions({eventData, handleFormChange}: SubscriptionsProps) {
  const [selectedCategory, setSelectedCategory] = useState<SubscriptionCategory>();

  const handleCategorySelect = (selectedId: string) => {
    console.log(selectedId)
    setSelectedCategory(eventData.subscriptioncategories.find((category) => category.id == selectedId));
  }

  const handleCategoryUpdate = (categoryUpdate: SubscriptionCategory[]) => {
    handleFormChange({
      subscriptioncategories: categoryUpdate
    });
  }

  const handleCategoryChangeInput = (key: keyof SubscriptionCategory, value: any, categoryId: string) => {
    const categoryUpdate = [...eventData.subscriptioncategories];
    const category = categoryUpdate.find(category => category.id === categoryId);
    if (category) {
      (category[key] as any) = value;
    }
    handleFormChange({
      subscriptioncategories: categoryUpdate
    });
  }

  return (
    <div className="subscriptions">
      <div className="px-36 py-16 flex">
        <div className="flex">
          <div>
            <h2 className="text-3xl font-bold mb-4">Categorias de Inscrição</h2>
            <SubscriptionsCategoryList
              eventData={eventData}
              subscriptionscategories={eventData.subscriptioncategories}
              onDelete={handleCategoryUpdate}
              onCreate={handleCategoryUpdate}
              onSelect={handleCategorySelect}
              onDrag={handleCategoryUpdate}
            />
          </div>
          <div className="flex-grow max-w-[800px] px-12">
            {selectedCategory ? (
              <EditSubscriptionCategoryForm 
                paymentdate={eventData.paymentdate}
                subscriptioncategory={selectedCategory}
                onChange={handleCategoryChangeInput}
              />
            ) : (
              <div className="flex items-center h-full">
                <p className="text-lg text-gray-300 font-bold mb-4">Selecione uma categoria para editar</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex grow">
          <div className="w-full">
            <h2 className="text-3xl font-bold mb-4">Categorias de Inscrição</h2>
            <textarea
              className="bg-gray-100 shadow-xl w-full h-96 border rounded-lg p-4 resize-none"
              placeholder="Digite a descrição das inscrições."
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscriptions;